import { OutlinedInput, Stack } from "@mui/material";
import { useState } from "react";
import { borderStyle, defaultGap, tinyGap } from "../../../styles/consts";
import { PrimaryButton } from "../../styles/Button.styles";
import NotImplemented from "../utils/NotImplemented";

export default function SearchBar({
  type,
  onSearch,
  notImplemented = false,
  setLoadingData,
}: {
  type: string;
  onSearch: (searchQuery: string) => void;
  notImplemented?: boolean;
  setLoadingData?: any;
}) {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = async () => {
    setLoadingData(true);

    await onSearch(searchQuery);

    setLoadingData(false);
  };

  const handleEnterClick = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Stack
      sx={{
        borderBottom: borderStyle,
        width: "100%",
        padding: defaultGap,
        boxSizing: "border-box",
      }}
    >
      <Stack sx={{ flexDirection: "row" }}>
        <OutlinedInput
          id="search-bar"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={handleEnterClick}
          autoComplete="off"
          sx={{
            borderRadius: "0",
            height: defaultGap,
          }}
        />
        {notImplemented ? (
          <NotImplemented popupLeft>
            <PrimaryButton
              sx={{
                padding: `${tinyGap} ${defaultGap}`,
              }}
            >
              Search {type}
            </PrimaryButton>
          </NotImplemented>
        ) : (
          <PrimaryButton
            id="search-button"
            onClick={handleSearch}
            sx={{
              padding: `${tinyGap} ${defaultGap}`,
            }}
          >
            Search {type}
          </PrimaryButton>
        )}
      </Stack>
    </Stack>
  );
}
