import { CircularProgress, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { PrimaryButton } from "../../../common/styles/Button.styles";
import { smallGap } from "../../../styles/consts";

export default function Messaging({ sendMessages, sendingMessages }) {
  const [message, setMessage] = useState(
    localStorage.getItem("linkedin_message") ||
      "Hello FULL_NAME! I saw your work on AI and would love to connect."
  );

  return (
    <>
      <TextField
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        multiline
      />
      <Stack
        sx={{
          gap: smallGap,
          flexDirection: "row",
        }}
      >
        <PrimaryButton
          onClick={() => {
            sendMessages(message);
          }}
        >
          Send messages
        </PrimaryButton>
        {sendingMessages && <CircularProgress />}
      </Stack>
    </>
  );
}
