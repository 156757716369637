import { Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import campaignService from "../../../api/CampaignScrapedProfilesService";
import userService from "../../../api/UserService";
import Dropdown from "../../../common/components/fundamentals/Dropdown";
import Loader from "../../../common/components/fundamentals/Loader/Loader";
import Table from "../../../common/components/table/Table";
import { useUser } from "../../../common/context/UserProvider";
import { User } from "../../../common/models/Models";
import { FullStack } from "../../../common/styles/Box.styles";
import { PrimaryButton } from "../../../common/styles/Button.styles";
import { defaultGap, hideScrollbar, smallGap } from "../../../styles/consts";
import LinkedinAuthFields from "../Linkedin/LinkedinAuthFields";

export default function CampaignDashboard() {
  const [loadingData, setLoadingData] = useState(true);
  const [profiles, setProfiles] = useState<any[]>([]);
  const [salesUsers, setSalesUsers] = useState<User[]>([]);
  const [messages, setMessages] = useState<any[]>([]);
  const [username, setUsername] = useState(
    localStorage.getItem("username") || ""
  );

  const { user } = useUser();

  const loadMessages = async () => {
    setMessages(await campaignService.getMessages());
  };

  const refreshUpdatedProfiles = (profile, updatedProfile) => {
    setProfiles((prev) =>
      prev.map((p) => (p.name === profile.name ? updatedProfile : p))
    );
  }

  const loadData = async () => {
    setLoadingData(true);
    setProfiles(await campaignService.getProfiles());
    setSalesUsers(await userService.getSalesUsers());
    await loadMessages();
    setLoadingData(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const setMessageAndUpdateProfiles = async (profile, value) => {
    const updatedProfile = await campaignService.updateProfile(profile.name, {
      message: value,
      accountManagerTodo: {
        userId: profile.accountManagerTodo?.userId || "",
        action: value ? "SEND_MESSAGE" : "TO_CONNECT",
      },
    });
    setProfiles((prev) =>
      prev.map((p) => (p.name === profile.name ? updatedProfile : p))
    );
  };

  const getProfilesRow = (profile) => {
    const messageDropdownOptions = [
      ...messages.map((message) => ({
        value: message.message,
      })),
      {
        value: "",
      },
    ];

    if (
      profile.message &&
      !messages.find((m) => m.message === profile.message)
    ) {
      messageDropdownOptions.unshift({
        value: profile.message,
      });
    }
    return [
      {
        sortParam: "name",
        label: "Name",
        component: (
          <Typography
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              window.open(profile.url, "_blank");
            }}
          >
            {profile.name}
          </Typography>
        ),
        width: "15%",
      },
      {
        sortParam: "jobtitle",
        label: "Title",
        component: <Typography>{profile.title}</Typography>,
        width: "20%",
      },
      {
        sortParam: "company",
        label: "Company",
        component: <Typography>{profile.company}</Typography>,
        width: "10%",
      },
      {
        sortParam: "location",
        label: "Location",
        component: <Typography>{profile.location}</Typography>,
        width: "10%",
      },
      {
        sortParam: "message",
        label: "Message",
        component: (
          <Dropdown
            minWidth="100%"
            id="messages-dropdown"
            initialSelected={profile?.message || ""}
            additionalContent={
              <TextField
                placeholder="Custom message for this person"
                onBlur={async (e) =>
                  setMessageAndUpdateProfiles(profile, e.target.value)
                }
                multiline
                minRows={3}
              />
            }
            options={messageDropdownOptions}
            onChange={async (value: string) =>
              setMessageAndUpdateProfiles(profile, value)
            }
            zIndexModifier={
              -1 * profiles.findIndex((p) => p.name === profile.name) * 10
            }
          />
        ),
        width: "20%",
      },
      {
        sortParam: "status",
        label: "Status",
        component: (
          <Dropdown
            minWidth="100%"
            id="status-dropdown"
            initialSelected={
              profile?.accountManagerTodo?.action || "TO_CONNECT"
            }
            options={[
              {
                value: "TO_CONNECT",
              },
              {
                value: "SEND_MESSAGE",
              },
              {
                value: "PENDING_CONNECTION",
              },
              {
                value: "PENDING_MESSAGE",
              }
            ]}
            onChange={async (value: string) => {
              const updatedProfile = await campaignService.updateProfile(
                profile.name,
                {
                  accountManagerTodo: {
                    userId: profile.accountManagerTodo.userId,
                    action: value,
                  },
                }
              );
              setProfiles((prev) =>
                prev.map((p) => (p.name === profile.name ? updatedProfile : p))
              );
            }}
            zIndexModifier={
              -1 * profiles.findIndex((p) => p.name === profile.name) * 10
            }
          />
        ),
        width: "10%",
        forceAlignLeft: true,
      },
      {
        sortParam: "accountManagerTodo",
        label: "Account Manager To do",
        component: (
          <Dropdown
            minWidth="100%"
            id="users-dropdown"
            initialSelected={profile?.accountManagerTodo?.userId || ""}
            options={salesUsers.map((user) => ({
              value: user.id,
              name: user.name,
            }))}
            onChange={async (value: string) => {
              const updatedProfile = await campaignService.updateProfile(
                profile.name,
                {
                  accountManagerTodo: {
                    userId: value,
                    action: "TO_CONNECT",
                  },
                }
              );
              refreshUpdatedProfiles(profile, updatedProfile);
            }}
            zIndexModifier={
              -1 * profiles.findIndex((p) => p.name === profile.name) * 10 + 5
            }
          />
        ),
        width: "10%",
        forceAlignLeft: true,
      },
    ];
  };

  const [createMessageInputValue, setCreateMessageInputValue] = useState("");

  const createAssignedConnectionsAndSendAssignedMessages = async () => {
    const profilesToConnect = profiles.filter(
      (p) =>
        p.accountManagerTodo?.action === "TO_CONNECT" &&
        p.accountManagerTodo.userId === user.id
    );

    const profilesToSendMessage = profiles.filter(
      (p) =>
        p.accountManagerTodo?.action === "SEND_MESSAGE" &&
        p.message &&
        p.accountManagerTodo.userId === user.id
    );

    if (!username) {
      alert("Please enter your username");
      return;
    }
    localStorage.setItem("username", username);
    console.log(profilesToSendMessage);
    // if (profilesToConnect.length > 0) {
    //   await linkedinService.connect({
    //     username: username,
    //     connections: profilesToConnect.map((profile) => ({
    //       "publicId": profile.url.split("/in/")[1].split("/")[0],
    //       ...profile
    //     })),
    //   });
    // }
    // if (profilesToSendMessage.length > 0) {
    //   await linkedinService.sendCustomMessages({
    //     username: username,
    //     connections: profilesToSendMessage.map((profile) => ({
    //       "publicId": profile.url.split("/in/")[1].split("/")[0],
    //       ...profile
    //     })),
    //   });
    // }
    profilesToConnect.forEach(async (profile) => {
      const updatedProfile = await campaignService.updateProfile(
        profile.name,
        {
          "accountManagerTodo.action": "PENDING_CONNECTION",
        }
      );
      refreshUpdatedProfiles(profile, updatedProfile);
    });
    profilesToSendMessage.forEach(async (profile) => {
      const updatedProfile = await campaignService.updateProfile(profile.name, {
        "accountManagerTodo.action": "PENDING_MESSAGE",
      });
      refreshUpdatedProfiles(profile, updatedProfile);
    });
  };

  return (
    <FullStack
      sx={{
        overflow: "auto",
        ...hideScrollbar,
        gap: smallGap,
        padding: defaultGap,
        boxSizing: "border-box",
      }}
    >
      <LinkedinAuthFields username={username} setUsername={setUsername} />
      <Stack
        sx={{
          gap: smallGap,
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <TextField
          value={createMessageInputValue}
          onChange={(e) => setCreateMessageInputValue(e.target.value)}
          placeholder="Hey FULL_NAME, I'm reaching out to you because..."
          sx={{
            width: "30%",
          }}
          multiline
        />
        <PrimaryButton
          onClick={async () => {
            if (!createMessageInputValue) {
              return;
            }
            await campaignService.createMessage({
              message: createMessageInputValue,
            });
            setCreateMessageInputValue("");
            await loadMessages();
          }}
        >
          Create message
        </PrimaryButton>
      </Stack>
      <PrimaryButton onClick={createAssignedConnectionsAndSendAssignedMessages}>
        Create assigned connections and send assigned messages
      </PrimaryButton>
      {loadingData ? (
        <Loader />
      ) : profiles.length > 0 ? (
        <Table
          items={profiles}
          getItemRow={getProfilesRow}
          idProperty="name"
          visibleRowsNo={10}
          defaultSort={{
            param: "name",
            order: "asc",
          }}
        />
      ) : (
        <Typography>No connections</Typography>
      )}
    </FullStack>
  );
}
