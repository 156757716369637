import { Box, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { BiggerIcon } from "../../../../../common/components/fundamentals/Icon";
import {
  FullStack,
  GreyOnHoverStack,
} from "../../../../../common/styles/Box.styles";
import { HeadingText } from "../../../../../common/styles/Text.styles";
import { borderStyle, checkboxGap } from "../../../../../styles/consts";
import { flip } from "../../../../../utils";

const popUpBackgroundClass = "popUpBackground";

export default function PopUpBox({
  button,
  heading,
  children,
  minHeight,
  maxHeight,
  height,
  width,
  onClose,
  closePopUp,
  setClosePopUp,
}: {
  button: any;
  heading?: string;
  children: any;
  minHeight?: string;
  maxHeight?: string;
  height?: string;
  width?: string;
  onClose?: () => void;
  closePopUp?: boolean;
  setClosePopUp?: any;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const renderChildren =
    typeof children === "function" ? children(setIsOpen) : children;

  const handleClosePopUp = useCallback(() => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        handleClosePopUp();
      }
    };

    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, handleClosePopUp]);

  useEffect(() => {
    if (closePopUp) {
      handleClosePopUp();
      setClosePopUp(false);
    }
  }, [closePopUp]);

  return (
    <Stack>
      <Box sx={{ width: "fit-content" }} onClick={flip(setIsOpen)}>
        {button}
      </Box>
      {isOpen && (
        <FullStack
          sx={{
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: "9999",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            justifyContent: "center",
            alignItems: "center",
            cursor: "crosshair",
          }}
          className={popUpBackgroundClass}
          onClick={(e: any) => {
            if (e.target.classList.contains(popUpBackgroundClass)) {
              flip(setIsOpen)();
              if (onClose) {
                onClose();
              }
            }
          }}
        >
          <Stack
            sx={{
              width: width ? width : "40%",
              minHeight: minHeight ? minHeight : "30%",
              maxHeight: maxHeight,
              height: height ? height : "fit-content",
              backgroundColor: "white",
              zIndex: "99999",
              cursor: "default",
            }}
          >
            <Stack
              sx={{
                paddingLeft: checkboxGap,
                border: borderStyle,
                borderBottom: "none",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <HeadingText
                sx={{
                  cursor: "text",
                }}
              >
                {heading}
              </HeadingText>

              <GreyOnHoverStack>
                <BiggerIcon
                  sx={{
                    height: "3rem",
                    width: "3rem",
                  }}
                  name="BigXMark"
                  onClick={handleClosePopUp}
                />
              </GreyOnHoverStack>
            </Stack>

            {renderChildren}
          </Stack>
        </FullStack>
      )}
    </Stack>
  );
}
