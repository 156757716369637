import { Image, Text, View } from "@react-pdf/renderer";
import { useContext } from "react";
import { BreakLine, RedBulletPointSquare, RedSlash } from "./Components";
import { horizontalPadding, perelynRed, verticalPadding } from "./const";
import { CVContentContext } from "./CVPDF";
import { HeaderRow, Row, Table } from "./TableComponents";
import { LargeTextPDF, SmallTextPDF } from "./Texts";

export default function FirstPageContent() {
  const cvContent = useContext<any>(CVContentContext);
  const lessThanTwoCertificates =
    !cvContent["certificates_logos"]?.length ||
    cvContent["certificates_logos"].length <= 1;
  return (
    cvContent && (
      <>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View
            style={{
              paddingLeft: horizontalPadding,
              paddingTop: verticalPadding,
              paddingBottom: verticalPadding,
              maxWidth: "70%",
            }}
          >
            <SmallTextPDF
              style={{
                color: perelynRed,
              }}
            >
              Profile
            </SmallTextPDF>
            <LargeTextPDF>{cvContent["fullName"]}</LargeTextPDF>
            <Text
              style={{
                fontSize: "14px",
                fontFamily: "ABCWhyte",
              }}
            >
              {cvContent["jobTitle"]}
            </Text>
            <SmallTextPDF>Perelyn, {cvContent["location"]}</SmallTextPDF>
          </View>
          <Image
            src={`/cv-generation/profile/${cvContent["fullName"].replaceAll(
              " ",
              "-"
            )}.jpg`}
            style={{
              width: "150px",
              height: "150px",
            }}
          />
        </View>
        <BreakLine />
        <View
          style={{
            padding: `${verticalPadding} ${horizontalPadding}`,
          }}
        >
          <SmallTextPDF
            style={{
              color: perelynRed,
            }}
          >
            Summary
          </SmallTextPDF>
          <SmallTextPDF>
            {cvContent["headlines"].map((headline, index) => (
              <Text key={index}>
                {headline}
                {index < cvContent["headlines"].length - 1 && <RedSlash />}
              </Text>
            ))}
          </SmallTextPDF>
        </View>
        <BreakLine />
        <View
          style={{
            padding: `${verticalPadding} ${horizontalPadding}`,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SmallTextPDF
            style={{
              color: perelynRed,
            }}
          >
            Education/Academics
          </SmallTextPDF>
          {cvContent["education"] &&
            cvContent["education"].map((edu, index) => (
              <View
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "16px",
                }}
              >
                <SmallTextPDF>{edu.degree}</SmallTextPDF>
              </View>
            ))}
        </View>
        <BreakLine />
        {cvContent["certificates"].length > 0 && (
          <>
            <View
              style={{
                padding: `${verticalPadding} ${horizontalPadding}`,
                display: "flex",
                flexDirection: lessThanTwoCertificates ? "row" : "column",
                alignItems: lessThanTwoCertificates ? "center" : "flex-start",
                justifyContent: lessThanTwoCertificates
                  ? "space-between"
                  : "center",
                gap: "16px",
              }}
            >
              <View style={{ maxWidth: "70%" }}>
                {cvContent["certificates"].map((certificate, index) => (
                  <View
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "16px",
                    }}
                  >
                    <RedBulletPointSquare
                      style={{
                        marginTop: "5px",
                      }}
                    />
                    <SmallTextPDF>
                      <SmallTextPDF
                        style={{
                          color: perelynRed,
                        }}
                      >
                        {certificate["label"]}
                      </SmallTextPDF>
                      {`: ${certificate["name"]}`}
                    </SmallTextPDF>
                  </View>
                ))}
              </View>
              {lessThanTwoCertificates &&
              cvContent["certificates_logos"]?.length > 0 ? (
                <Image
                  src={cvContent["certificates_logos"].find(() => true)}
                  style={{
                    width: "125px",
                  }}
                />
              ) : (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    flexWrap: "wrap",
                  }}
                >
                  {cvContent["certificates_logos"]?.map((logo, index) => (
                    <Image
                      key={index}
                      src={logo}
                      style={{
                        width: "125px",
                      }}
                    />
                  ))}
                </View>
              )}
            </View>
            <BreakLine />
          </>
        )}
        <View style={{ padding: horizontalPadding }}>
          <Table>
            <HeaderRow label="Nationality / Languages" />
            <Row>
              <SmallTextPDF
                style={{
                  width: "30%",
                }}
              >
                Nationality:
              </SmallTextPDF>
              <SmallTextPDF>{cvContent["nationality"]}</SmallTextPDF>
            </Row>
            <Row last>
              <SmallTextPDF
                style={{
                  width: "30%",
                }}
              >
                Languages:
              </SmallTextPDF>
              <View>
                {cvContent["languages"].map((language, index) => (
                  <View
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <SmallTextPDF
                      style={{
                        width: "40%",
                      }}
                    >
                      {language.name}:
                    </SmallTextPDF>
                    <SmallTextPDF>{language.level}</SmallTextPDF>
                  </View>
                ))}
              </View>
            </Row>
          </Table>
        </View>
      </>
    )
  );
}
