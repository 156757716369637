import {
  Box,
  Checkbox,
  CircularProgress,
  Stack,
  TextField,
} from "@mui/material";
import { ReactNode, useState } from "react";
import { FullStack } from "../../../common/styles/Box.styles";
import { PrimaryButton } from "../../../common/styles/Button.styles";
import {
  HeadingText,
  MediumText,
  SmallText,
} from "../../../common/styles/Text.styles";
import {
  defaultGap,
  hideScrollbar,
  iconGap,
  smallGap,
} from "../../../styles/consts";
import ConnectionsAndMessages from "./ConnectionsAndMessages";
import LinkedinAuthFields from "./LinkedinAuthFields";

export type Field = {
  name: string;
  description?: string;
  default: string | boolean;
  type?: "list" | "int" | "bool";
};

export default function LinkedinDashboardBase({
  title,
  fields,
  onSubmit,
  submitButtonText,
  additionalButtons,
  icp = false,
}: {
  title?: string;
  fields: Field[];
  onSubmit: (
    e: React.FormEvent<HTMLFormElement>,
    formData: Record<string, any>
  ) => Promise<any>;
  submitButtonText: string;
  additionalButtons?: ReactNode;
  icp?: boolean;
}) {
  const [response, setResponse] = useState<any>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [username, setUsername] = useState(
    localStorage.getItem("username") || ""
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);
    setResponse(null);
    try {
      const data = new FormData(e.currentTarget);
      const body: Record<string, any> = {
        username,
      };

      localStorage.setItem("username", username);

      fields.forEach((field) => {
        const value = data.get(field.name)?.toString() || "";
        localStorage.setItem(field.name, value);

        if (!value) {
          body[field.name] = null;
        } else if (field.type === "list") {
          const list = value.split(",").map((item) => item.trim());
          body[field.name] = list.length ? list : null;
        } else if (field.type === "int") {
          body[field.name] = value ? parseInt(value) : null;
        } else if (field.type === "bool") {
          body[field.name] = value.toLowerCase() === "on";
        } else {
          body[field.name] = value || null;
        }
      });

      const result = await onSubmit(e, body);
      setResponse(result);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <FullStack
      sx={{
        overflow: "auto",
        ...hideScrollbar,
      }}
    >
      <Box component="form" onSubmit={handleSubmit}>
        <Stack
          sx={{
            gap: smallGap,
            padding: defaultGap,
          }}
        >
          {title && <HeadingText>{title}</HeadingText>}
          <Stack
            sx={{
              gap: smallGap,
              flexDirection: "column",
              flexWrap: "wrap",
              height: "50vh",
            }}
          >
            <LinkedinAuthFields username={username} setUsername={setUsername} />
            {fields.map((field) =>
              field.type !== "bool" ? (
                <Stack
                  sx={{
                    gap: iconGap,
                    width: "50%",
                  }}
                  key={field.name}
                >
                  <Stack>
                    <MediumText>{field.name}</MediumText>
                    {field.description && (
                      <SmallText color="grey.500">
                        {field.description}
                      </SmallText>
                    )}
                  </Stack>
                  <TextField
                    id={field.name}
                    name={field.name}
                    defaultValue={
                      localStorage.getItem(field.name) || field.default
                    }
                  />
                </Stack>
              ) : (
                <Stack
                  key={field.name}
                  sx={{
                    gap: iconGap,
                  }}
                >
                  <Stack>
                    <MediumText>{field.name}</MediumText>
                    {field.description && (
                      <SmallText color="grey.500">
                        {field.description}
                      </SmallText>
                    )}
                  </Stack>
                  <Checkbox
                    id={field.name}
                    name={field.name}
                    defaultChecked={
                      localStorage.getItem(field.name) === "on" ||
                      (field.default as boolean)
                    }
                  />
                </Stack>
              )
            )}
          </Stack>
          <Stack sx={{ gap: smallGap, flexDirection: "row" }}>
            <PrimaryButton
              id="next-step"
              type="submit"
              sx={{ fontSize: "1rem" }}
            >
              {submitButtonText}
            </PrimaryButton>
            {isProcessing && <CircularProgress />}
          </Stack>
          {response && (
            <Stack>
              <MediumText>Response</MediumText>
              <pre style={{ whiteSpace: "pre-wrap" }}>
                {JSON.stringify(response, null, 2)}
              </pre>
            </Stack>
          )}
        </Stack>
      </Box>
      {additionalButtons}
      <ConnectionsAndMessages refreshers={[response]} icp={icp} />
    </FullStack>
  );
}
