import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import {
  borderStyle,
  boxHeight,
  defaultGap,
  smallGap,
} from "../../../styles/consts";
import { NavigationButton } from "../../styles/Button.styles";

export default function PageNavigation({
  page,
  setPage,
  filteredItems,
  visibleRowsNo,
  callOnNextPage,
  storageKey,
}: {
  page: number;
  setPage: any;
  filteredItems: any[];
  visibleRowsNo: number;
  callOnNextPage?: () => void;
  storageKey?: string;
}) {
  const amountOfPages = Math.ceil(filteredItems.length / visibleRowsNo);

  useEffect(() => {
    if (storageKey) {
      localStorage.setItem(storageKey, page.toString());
    }
  }, [page, storageKey]);

  return (
    <Stack
      sx={{
        borderTop: borderStyle,
        height: boxHeight,
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        minHeight: boxHeight,
        maxHeight: boxHeight,
        padding: `0.75rem ${defaultGap} ${smallGap}`,
        boxSizing: "border-box",
        alignItems: "center",
      }}
    >
      <NavigationButton
        onClick={() => {
          if (page === 1) {
            return;
          }
          setPage((prev) => prev - 1);
        }}
        disabled={page === 1}
      >
        Previous
      </NavigationButton>
      <Typography>
        Page {page} of {amountOfPages === 0 ? 1 : amountOfPages}
      </Typography>
      <NavigationButton
        onClick={() => {
          setPage((prev) => {
            if (prev * visibleRowsNo > filteredItems.length) {
              return prev;
            }
            return prev + 1;
          });
          callOnNextPage && callOnNextPage();
        }}
        disabled={page * visibleRowsNo >= filteredItems.length}
      >
        Next
      </NavigationButton>
    </Stack>
  );
}
