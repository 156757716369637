import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import alqemiService from "../../../../api/AlqemiService";
import configService from "../../../../api/ConfigService";
import feedbackService from "../../../../api/FeedbackService";
import alqemiPostgresService from "../../../../api/PostgresService";
import Icon, {
  BiggerIcon,
} from "../../../../common/components/fundamentals/Icon";
import { useSnackbar } from "../../../../common/context/SnackbarProvider";
import { AlqemiSimpleInput } from "../../../../common/models/Models";
import {
  OutlinedButton,
  PrimaryButton,
} from "../../../../common/styles/Button.styles";
import { TaskStatusIcon } from "../../../../common/styles/Form.styles";
import { MediumText, SmallText } from "../../../../common/styles/Text.styles";
import {
  borderOrange,
  borderStyle,
  defaultTransition,
  iconGap,
  perelynBlack,
  perelynGrey,
  perelynMediumGreen,
  perelynPrimary,
  perelynSuccess,
  smallerGap,
  smallGap,
  tinyGap,
} from "../../../../styles/consts";
import PopUpBox from "../../MarketingTextTool/components/result/PopUpBox";
import { useOffer } from "../context/OfferProvider";

export default function ButtonSection({
  emailContent,
  pdfDownloadBlob,
  pdfFileName,
  displayedMatch,
  setIsGenerating,
}: {
  emailContent: string;
  pdfDownloadBlob: Blob | null;
  pdfFileName: string;
  displayedMatch: any;
  setIsGenerating: (value: boolean) => void;
}) {
  const { showSnackbar } = useSnackbar();
  const [alqemiMatchingConfig, setAlqemiMatchingConfig] = useState<any>(null);
  const [emailTemplate, setEmailTemplate] = useState("");
  const [emailPrompt, setEmailPrompt] = useState("");
  const [cvPrompt, setCvPrompt] = useState("");
  const [closePopUp, setClosePopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { offer } = useOffer();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("md", "xl"));
  const numOfRows = isSmallScreen ? 20 : 35;

  const handleSendEmail = () => {
    alqemiPostgresService.updateMatch(displayedMatch.id, "status", "sent");
    showSnackbar(`Offer marked as "Sent!"`);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(emailContent.toString());
    showSnackbar("Copied to clipboard!");
  };

  const handleDownloadCV = () => {
    if (pdfDownloadBlob) {
      const aTag = document.createElement("a");
      aTag.href = URL.createObjectURL(pdfDownloadBlob);
      aTag.style.display = "none";
      aTag.download = pdfFileName + ".pdf";
      document.body.appendChild(aTag);
      aTag.click();
    }
  };

  const handleMarkAsNew = () => {
    alqemiPostgresService.updateMatch(displayedMatch.id, "status", "completed");
    showSnackbar(`Offer marked as "New"!`);
  };

  const handleReRunEmail = async () => {
    setIsLoading(true);

    const candidateId = displayedMatch.candidate.id;
    const candidates = await alqemiPostgresService.getCandidates();

    const candidateIndex = candidates.findIndex(
      (candidate) => candidate.consultant_id === candidateId
    );

    const cv = candidates[candidateIndex].cv_template_full;

    const simpleInput: AlqemiSimpleInput = {
      jobOffer: offer,
      cv: cv,
      matchingId: displayedMatch.id,
    };

    await alqemiService.reRunMatching(
      cvPrompt,
      emailTemplate,
      emailPrompt,
      simpleInput
    );
    await feedbackService.createFeedback({
      matchId: displayedMatch.id,
      feedback: `Email template: ${emailTemplate},\n\nEmail prompt: ${emailPrompt}, \n\nCV prompt: ${cvPrompt}`,
    });

    setClosePopUp(true);
    setIsLoading(false);
    setIsGenerating(true);
    showSnackbar("Re-running matching", false);

    const pollForEmail = async (attempts = 0): Promise<void> => {
      if (attempts > 10) {
        showSnackbar("Re-running matching timed out", true, false);
        setIsLoading(false);
        return;
      }
      const delay = attempts === 0 ? 30000 : 10000;
      await new Promise((resolve) => setTimeout(resolve, delay));

      const result = await alqemiPostgresService.getMatch(
        displayedMatch.id,
        displayedMatch.updated_at
      );

      if (result.status === "updated") {
        displayedMatch.email = result.data.email;
        alqemiPostgresService.updateMatch(
          displayedMatch.id,
          "status",
          "viewed"
        );
        showSnackbar("Re-running matching completed!", false, false);
        setIsGenerating(false);

        return;
      }
      return pollForEmail(attempts + 1);
    };
    pollForEmail();
  };

  const getAlqemiMatchingConfig = async () => {
    const config = await configService.getAlqemiConfiguration();
    setAlqemiMatchingConfig(config);
    return config;
  };

  useEffect(() => {
    getAlqemiMatchingConfig();
  }, []);

  useEffect(() => {
    if (alqemiMatchingConfig) {
      setEmailTemplate(
        alqemiMatchingConfig?.nodes?.WriteEmailNode?.email_template?.value[0]
          .email_template
      );
      setEmailPrompt(
        alqemiMatchingConfig?.nodes?.WriteEmailNode?.promptPresets.value.prompts
          .systemPrompt1
      );
      setCvPrompt(
        alqemiMatchingConfig.nodes.CVGeneratorNode.promptPresets.value.prompts
          .systemPrompt1
      );
    }
  }, [alqemiMatchingConfig]);

  return (
    <Stack
      sx={{
        flexDirection: "row",
        justifyContent: "flex-end",
        gap: smallGap,
      }}
    >
      <PopUpBox
        heading="Adjust matching prompts"
        button={<OutlinedButton>Re-run matching</OutlinedButton>}
        width="90%"
        maxHeight="90%"
        closePopUp={closePopUp}
        setClosePopUp={setClosePopUp}
      >
        <Stack
          sx={{
            border: borderStyle,
            padding: smallGap,
            gap: smallGap,
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              width: "100%",
              gap: smallGap,
              boxSizing: "border-box",
            }}
          >
            <Stack sx={{ width: "100%" }}>
              <MediumText>Template</MediumText>
              <TextField
                id="email-template"
                value={emailTemplate}
                multiline
                maxRows={numOfRows}
                minRows={numOfRows}
                onChange={(e) => setEmailTemplate(e.target.value)}
              />
            </Stack>
            <Stack sx={{ width: "100%" }}>
              <MediumText>Email Prompt</MediumText>
              <TextField
                id="email-prompt"
                value={emailPrompt}
                multiline
                maxRows={numOfRows}
                minRows={numOfRows}
                onChange={(e) => setEmailPrompt(e.target.value)}
              />
            </Stack>
            <Stack sx={{ width: "100%" }}>
              <MediumText>CV Prompt</MediumText>
              <TextField
                id="cv-prompt"
                value={cvPrompt}
                multiline
                maxRows={numOfRows}
                minRows={numOfRows}
                onChange={(e) => setCvPrompt(e.target.value)}
              />
            </Stack>
          </Stack>
          <Stack sx={{ flexDirection: "row", justifyContent: "end" }}>
            <PrimaryButton
              id="generate"
              onClick={handleReRunEmail}
              sx={{
                justifyContent: "center",
                "&&": {
                  width: "6.5rem",
                },
                ":hover": {
                  "& .MuiCircularProgress-root": {
                    color: perelynPrimary,
                  },
                },
              }}
            >
              {isLoading ? (
                <CircularProgress
                  sx={{ color: perelynBlack }}
                  size={smallerGap}
                />
              ) : (
                "Generate"
              )}
            </PrimaryButton>
          </Stack>
        </Stack>
      </PopUpBox>

      <Button
        id="mark-as-new"
        onClick={handleMarkAsNew}
        sx={{
          gap: iconGap,
          border: borderStyle,
          "&:hover": {
            backgroundColor: "white",
            border: borderOrange,
          },
        }}
      >
        <Typography>Mark as</Typography>
        <TaskStatusIcon
          sx={{
            backgroundColor: perelynMediumGreen,
            opacity: 0.9,
          }}
        >
          <SmallText sx={{ color: perelynSuccess }}>New</SmallText>
        </TaskStatusIcon>
      </Button>
      <OutlinedButton
        id="download-cv"
        onClick={handleDownloadCV}
        sx={{
          borderColor: perelynGrey,
          padding: tinyGap,
          color: perelynGrey,
          gap: "0.5rem",
        }}
      >
        CV
        <Icon
          sx={{
            height: "1.5rem",
            width: "1.5rem",
          }}
          name="Download"
        />
      </OutlinedButton>
      <OutlinedButton onClick={handleCopyToClipboard}>
        <BiggerIcon name="Copy" />
      </OutlinedButton>
      <PrimaryButton
        id="mark-as-sent"
        sx={{
          gap: tinyGap,

          "&:hover": {
            "& > *": {
              transition: defaultTransition,
              filter:
                "brightness(0) saturate(100%) invert(48%) sepia(27%) saturate(1354%) hue-rotate(315deg) brightness(98%) contrast(107%)",
            },
          },
          "&:disabled": {
            "& > *": {
              filter:
                "brightness(0) saturate(100%) invert(70%) sepia(9%) saturate(77%) hue-rotate(22deg) brightness(91%) contrast(86%)",
            },
          },
        }}
        // disabled={offer.matches.at(index)?.status === "sent"}
        onClick={handleSendEmail}
      >
        {/* Send Email */}Mark as Sent
        <BiggerIcon name="SendRight" />
      </PrimaryButton>
    </Stack>
  );
}
