import { Stack, Typography } from "@mui/material";
import { pdf, PDFViewer } from "@react-pdf/renderer";
import { createContext, useEffect, useState, useMemo } from "react";
import { CVTemplateFull } from "../common/models/Models";
import Doc from "./Doc";

export const CVContentContext = createContext(null);

export default function CVPDF({
  cvContent,
  setPdfDownloadBlob,
}: {
  cvContent: CVTemplateFull | null;
  setPdfDownloadBlob: (blob: Blob | null) => void;
}) {
  const [document, setDocument] = useState<JSX.Element | null>(null);

  const isValidCV = useMemo(() => {
    return !!(
      cvContent &&
      cvContent.jobTitle &&
      cvContent.fullName &&
      cvContent.headlines &&
      cvContent.languages &&
      cvContent.location &&
      cvContent.nationality &&
      cvContent.professionalCareer &&
      cvContent.projects &&
      cvContent.tech
    );
  }, [cvContent]);

  useEffect(() => {
    const getRenderBlob = async () => {
      if (isValidCV) {
        const doc = <Doc cvContent={cvContent} />;
        setDocument(doc);
        const blob = await pdf(doc).toBlob();
        setPdfDownloadBlob(blob);
      } else {
        setPdfDownloadBlob(null);
      }
    };

    getRenderBlob();
  }, [cvContent, setPdfDownloadBlob, isValidCV]);

  return (
    <Stack
      sx={{
        flexDirection: "column",
        width: "100%",
        minHeight: "100%",
        height: "100%",
      }}
    >
      {isValidCV && document ? (
        <PDFViewer
          style={{
            height: "100%",
          }}
          showToolbar={false}
        >
          {document}
        </PDFViewer>
      ) : (
        <Stack
          sx={{
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>CV not available</Typography>
        </Stack>
      )}
    </Stack>
  );
}
