import { Config } from "@testing-library/react";
import { apiAxios } from "./axios";

const ALQEMI_CONFIG_URL = "/alqemi-configuration";

class ConfigService {
  async getAlqemiConfiguration(): Promise<Config> {
    return (await apiAxios.get<Config>(ALQEMI_CONFIG_URL)).data;
  }

  async updateAlqemiConfiguration(updateProperties: object): Promise<any> {
    return (
      await apiAxios.post(ALQEMI_CONFIG_URL, {
        update_properties: updateProperties,
      })
    ).data;
  }
}

const configService = new ConfigService();
export default configService;
