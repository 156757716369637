import { Box, TextField } from "@mui/material";
import { useState } from "react";
import feedbackService from "../../../../api/FeedbackService";
import { useSnackbar } from "../../../../common/context/SnackbarProvider";
import { OutlinedButton } from "../../../../common/styles/Button.styles";
import { perelynRed } from "../../../../cv-generation/const";
import {
  defaultTransition,
  perelynBlack,
  perelynGrey,
  smallGap,
} from "../../../../styles/consts";

export default function AlqemiFeedback({ displayedMatchId }) {
  const [feedbackText, setFeedbackText] = useState("");
  const [rating, setRating] = useState<number>();
  const { showSnackbar } = useSnackbar();
  return (
    <>
      <TextField
        placeholder="Feedback"
        autoComplete="off"
        multiline
        minRows={10}
        maxRows={20}
        value={feedbackText}
        onChange={(e) => setFeedbackText(e.target.value)}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: smallGap,
            alignItems: "center",
            height: "5rem",
          }}
        >
          {[...Array(10)].map((e, i) => {
            const isCurrentlySelected = rating === i + 1;
            return (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: isCurrentlySelected ? "3rem" : "2rem",
                  height: isCurrentlySelected ? "3rem" : "2rem",
                  fontSize: isCurrentlySelected ? "18px" : "14px",
                  color: i < 3 ? "white" : "black",
                  transition: defaultTransition,
                  backgroundColor:
                    i < 3 ? perelynBlack : i < 7 ? perelynGrey : perelynRed,
                  borderRadius: "200px",
                  cursor: "pointer",
                  boxShadow: "0 0 1rem rgba(0, 0, 0, 0.125)",
                  fontWeight: isCurrentlySelected ? "600" : "400",
                  "&:hover": {
                    opacity: "0.5",
                  },
                }}
                onClick={() => setRating(i + 1)}
              >
                {i + 1}
              </Box>
            );
          })}
        </Box>
      </Box>
      <OutlinedButton
        onClick={async () => {
          await feedbackService.createFeedback({
            matchId: displayedMatchId,
            feedback: feedbackText,
            rating: rating,
            offerUrl: window.location.href,
          });
          setFeedbackText("");
          setRating(undefined);
          showSnackbar("Feedback sent successfully", false, true);
        }}
      >
        Send Feedback
      </OutlinedButton>
    </>
  );
}
