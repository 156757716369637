import { CircularProgress, Stack, TextField } from "@mui/material";
import { useState } from "react";
import alqemiService from "../../../../api/AlqemiService";
import { BiggerIcon } from "../../../../common/components/fundamentals/Icon";
import { useSnackbar } from "../../../../common/context/SnackbarProvider";
import { useUser } from "../../../../common/context/UserProvider";
import {
  OutlinedButton,
  PrimaryButton,
} from "../../../../common/styles/Button.styles";
import { MediumText } from "../../../../common/styles/Text.styles";
import {
  borderStyle,
  defaultTransition,
  iconGap,
  perelynBlack,
  perelynPrimary,
  smallerGap,
  smallGap,
} from "../../../../styles/consts";
import PopUpBox from "../../MarketingTextTool/components/result/PopUpBox";
import { useOffer } from "../context/OfferProvider";

export default function ConfirmationButtons({
  displayedMatch,
}: {
  displayedMatch: any;
}) {
  const { offer } = useOffer();
  const { user } = useUser();
  const { showSnackbar } = useSnackbar();
  const [closePopUp, setClosePopUp] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const isSupervisor = user.email === displayedMatch.candidate.supervisor_email;
  const isCandidate = user.email === displayedMatch.candidate.email;
  const jobTitle = offer.title;
  const isSales = user.email === "philipp.ullmann@perelyn.com";

  const isAlreadyConfirmedByConsultant =
    displayedMatch.consultant_confirmed === true;
  const isAlreadyConfirmedBySupervisor =
    displayedMatch.supervisor_confirmed === true;
  const isConsultantYetToConfirm =
    displayedMatch.consultant_confirmed === false;
  const isSupervisorYetToConfirm =
    displayedMatch.supervisor_confirmed === false;

  const isConfirmButtonDisabledForCandidate =
    isCandidate && (isAlreadyConfirmedByConsultant || isConfirmed);

  const isConfirmButtonDisabledForSupervisor =
    !isSales &&
    (isAlreadyConfirmedBySupervisor ||
      isConfirmed ||
      (isSupervisor && isConsultantYetToConfirm));

  const handleCandidateConfirmCV = async () => {
    setIsConfirmed(true);
    showSnackbar("CV confirmed! Notification has been sent to your supervisor");
    await alqemiService.sendAlqemiEmail({
      displayedMatch,
      isSupervisor,
      jobTitle,
    });
  };

  const handleSupervisorConfirmCV = async () => {
    setIsConfirmed(true);
    showSnackbar("CV confirmed! Notification has been sent to the sales team");
    await alqemiService.sendAlqemiEmail({
      displayedMatch,
      isSupervisor,
      jobTitle,
    });
  };

  const handleSendFeedback = async () => {
    setIsLoading(true);
    setIsConfirmed(true);

    if (isSales) {
      await alqemiService.sendAlqemiEmail({
        displayedMatch,
        isSupervisor,
        jobTitle,
        feedback,
        isSales,
      });
      showSnackbar("Feedback sent to supervisor");
    } else {
      await alqemiService.sendAlqemiEmail({
        displayedMatch,
        isSupervisor,
        jobTitle,
        feedback,
        isSales,
      });
      showSnackbar("Feedback sent to candidate");
    }
    setClosePopUp(true);
    setFeedback("");
    setIsLoading(false);
  };

  const confirmButtonText = (() => {
    if (
      (isCandidate && (isAlreadyConfirmedByConsultant || isConfirmed)) ||
      (!isCandidate && (isAlreadyConfirmedBySupervisor || isConfirmed))
    ) {
      return "CV confirmed";
    }
    return "Confirm CV";
  })();

  const getFeedbackButtonText = () => {
    const baseText = "Send";
    const recipient = isSales ? "supervisor" : "candidate";
    const needsBackText = !(
      (isSupervisor && isConsultantYetToConfirm) ||
      (isSales && isSupervisorYetToConfirm)
    );

    return `${baseText}${needsBackText ? " back" : ""} to ${recipient}`;
  };

  return (
    <>
      {(isCandidate || (!isSales && isSupervisor)) && (
        <PrimaryButton
          disabled={
            isCandidate
              ? isConfirmButtonDisabledForCandidate
              : isConfirmButtonDisabledForSupervisor
          }
          onClick={
            isCandidate ? handleCandidateConfirmCV : handleSupervisorConfirmCV
          }
          sx={{
            gap: iconGap,
            "&:hover": {
              "& > *": {
                transition: defaultTransition,
                filter:
                  "brightness(0) saturate(100%) invert(48%) sepia(27%) saturate(1354%) hue-rotate(315deg) brightness(98%) contrast(107%)",
              },
            },
          }}
        >
          {confirmButtonText}
          <BiggerIcon name="Check" />
        </PrimaryButton>
      )}

      {(isSales || isSupervisor) && (
        <PopUpBox
          heading="Feedback"
          button={
            <OutlinedButton
              disabled={
                !isSales && (isAlreadyConfirmedBySupervisor || isConfirmed)
              }
              sx={{ gap: iconGap }}
            >
              {getFeedbackButtonText()}
              <BiggerIcon name="FlipBackward" />
            </OutlinedButton>
          }
          width="90%"
          maxHeight="90%"
          closePopUp={closePopUp}
          setClosePopUp={setClosePopUp}
        >
          <Stack
            sx={{
              border: borderStyle,
              padding: smallGap,
              gap: smallGap,
            }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                width: "100%",
                gap: smallGap,
                boxSizing: "border-box",
              }}
            >
              <Stack sx={{ width: "100%" }}>
                <MediumText>Describe what should be improved</MediumText>
                <TextField
                  id="email-template"
                  placeholder="Write here..."
                  multiline
                  minRows={10}
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                />
              </Stack>
            </Stack>
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "end",
              }}
            >
              <PrimaryButton
                sx={{
                  "&&": { width: "8rem" },
                  ":hover": {
                    "& .MuiCircularProgress-root": {
                      color: perelynPrimary,
                    },
                  },
                }}
                onClick={handleSendFeedback}
                disabled={!feedback || feedback.trim() === ""}
              >
                {isLoading ? (
                  <CircularProgress
                    sx={{ color: perelynBlack }}
                    size={smallerGap}
                  />
                ) : (
                  "Send Feedback"
                )}
              </PrimaryButton>
            </Stack>
          </Stack>
        </PopUpBox>
      )}
    </>
  );
}
