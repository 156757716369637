import { Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import OfferAutoUpdateTextField from "../../../../common/components/fundamentals/OfferAutoUpdateTextField";
import { FullStack } from "../../../../common/styles/Box.styles";
import CVPDF from "../../../../cv-generation/CVPDF";
import {
  borderStyle,
  defaultTransition,
  smallerGap,
  smallGap,
} from "../../../../styles/consts";
import { useOffer } from "../context/OfferProvider";
import ButtonSection from "./ButtonSection";
import Candidate from "./Candidate";
import OfferHeading from "./OfferHeading";

import alqemiPostgresService from "../../../../api/PostgresService";
import Loader from "../../../../common/components/fundamentals/Loader/Loader";
import { MediumText } from "../../../../common/styles/Text.styles";
import AlqemiFeedback from "./AlqemiFeedback";
import CVEdit from "./CVEdit";
import ConfirmationButtons from "./ConfirmationButtons";

export default function AlqemiMatchPage() {
  const { offer } = useOffer();
  const [displayedMatchIndex, setDisplayedMatchIndex] = useState(0);
  const displayedMatch = offer?.matches?.[displayedMatchIndex];
  const [pdfDownloadBlob, setPdfDownloadBlob] = useState<Blob | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);

  const handleCandidateClick = (index: number) => {
    setDisplayedMatchIndex(index);
  };

  useEffect(() => {
    if (
      displayedMatch?.status === "viewed" ||
      displayedMatch?.status === "sent"
    ) {
      return;
    } else if (displayedMatch) {
      alqemiPostgresService.updateMatch(displayedMatch.id, "status", "viewed");
    }
  }, [displayedMatch]);

  const emailContent =
    displayedMatch && displayedMatch.email ? displayedMatch.email : "";

  const [cvContent, setCvContent] = useState(
    displayedMatch && displayedMatch.cv ? displayedMatch.cv : {}
  );

  useEffect(() => {
    setCvContent(displayedMatch?.cv);
  }, [displayedMatch?.cv]);

  return (
    <FullStack>
      <OfferHeading
        title={offer?.title}
        companyName={offer?.ansprechpartner}
        url={offer?.url}
      />

      {!displayedMatch ? (
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <MediumText>Waiting for the matching process to finish.</MediumText>
          <Typography>Try again later.</Typography>
        </Stack>
      ) : (
        <>
          <TextField
            multiline
            value={offer.beschreibung}
            sx={{
              padding: smallGap,
              paddingBottom: "0rem",
              position: "relative",
              display: "flex",
              flexDirection: "column",

              "& .MuiInputBase-root": {
                resize: "vertical",
                overflow: "hidden",
                minHeight: "10rem",
                height: "10rem",
                maxHeight: "40rem",
                width: "100%",
                transition: "none",
                flexDirection: "column",
              },

              "& .MuiInputBase-input": {
                overflow: "auto !important",
              },
            }}
          />
          <Stack sx={{ padding: smallGap, gap: smallGap }}>
            <Stack sx={{ flexDirection: "row", gap: smallGap }}>
              {offer.matches.map((match, idx) => (
                <Candidate
                  key={idx}
                  name={match.candidate.name}
                  isDisplayed={idx === displayedMatchIndex}
                  onClick={() => handleCandidateClick(idx)}
                />
              ))}
            </Stack>

            <Stack
              sx={{
                flexDirection: "row",
                gap: smallerGap,
              }}
            >
              {/* {isGenerating ? (
                <Stack
                  sx={{
                    transition: defaultTransition,
                    border: borderStyle,
                    height: "80vh",
                    width: "50%",
                    gap: smallGap,
                  }}
                >
                  <Loader />
                </Stack>
              ) : ( */}
              <Stack
                sx={{
                  transition: defaultTransition,
                  border: borderStyle,
                  height: "80vh",
                  width: "50%",
                  gap: smallGap,
                }}
              >
                <CVPDF
                  cvContent={cvContent}
                  setPdfDownloadBlob={setPdfDownloadBlob}
                />
                <Stack sx={{ flexDirection: "row", gap: smallGap }}>
                  <CVEdit
                    cvContent={cvContent}
                    displayedMatch={displayedMatch}
                    setCvContent={setCvContent}
                  />
                  <ConfirmationButtons displayedMatch={displayedMatch} />
                </Stack>
              </Stack>
              {/* )} */}

              <Stack sx={{ width: "50%", gap: smallGap }}>
                {isGenerating ? (
                  <FullStack sx={{ border: borderStyle, minHeight: "60vh" }}>
                    <Loader />
                  </FullStack>
                ) : (
                  <OfferAutoUpdateTextField
                    key={displayedMatchIndex}
                    id="email-content"
                    defaultValue={emailContent}
                    matchIndex={displayedMatchIndex}
                    propPath={`candidates[${displayedMatchIndex}].emailContent`}
                    multiline
                    rows={20}
                  />
                )}

                <ButtonSection
                  emailContent={emailContent}
                  pdfDownloadBlob={pdfDownloadBlob}
                  pdfFileName={`${displayedMatch.candidate.name}-${offer.title}`}
                  displayedMatch={displayedMatch}
                  setIsGenerating={setIsGenerating}
                />
                <AlqemiFeedback displayedMatchId={displayedMatch.id} />
              </Stack>
            </Stack>
          </Stack>
        </>
      )}
      {displayedMatch && (
        <Stack sx={{ padding: smallGap, paddingTop: "5rem" }}>
          <Typography>Explanation:</Typography>
          <pre style={{ whiteSpace: "pre-wrap" }}>
            {displayedMatch && displayedMatch.explanation
              ? JSON.stringify(displayedMatch.explanation, null, 2)
              : "No explanation available"}
          </pre>
        </Stack>
      )}
    </FullStack>
  );
}
