import { apiAxios, linkedinAxios } from "./axios";

class LinkedinService {
  async createConnections(connectionsProperties: any): Promise<any> {
    return (
      await linkedinAxios.post<any>("/connections", connectionsProperties)
    ).data;
  }

  async createICPConnections(connectionsProperties: any): Promise<any> {
    return (await linkedinAxios.post<any>("/icp", connectionsProperties)).data;
  }

  async getConnections(
    queryParams: { key: string; value: string }[]
  ): Promise<any> {
    const queryString = queryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");
    return (
      await apiAxios.get<any>(
        `/connections${queryString ? "?" + queryString : ""}`
      )
    ).data;
  }

  async updateMonitorCareerChange(connection: any): Promise<any> {
    return (await apiAxios.put<any>("/monitor-career-change", connection)).data;
  }

  async updateICPStatus(connection: any): Promise<any> {
    return (await apiAxios.put<any>("/icp-status", connection)).data;
  }

  async importConnections(importProperties: any): Promise<any> {
    return (await linkedinAxios.post<any>("/import", importProperties)).data;
  }

  async sendMessages(messageProperties: any): Promise<any> {
    return (await linkedinAxios.post<any>("/messages", messageProperties)).data;
  }
  async runCareerChangeMonitoring(runProperties: any): Promise<any> {
    return (
      await linkedinAxios.post<any>(
        "/run-career-change-monitoring",
        runProperties
      )
    ).data;
  }

  async updateLinkedinCredentials(credentialsRequest: any): Promise<any> {
    return (
      await apiAxios.put<any>("/linkedin-credentials", credentialsRequest)
    ).data;
  }

  async connect(connectionProperties: any): Promise<any> {
    return (await linkedinAxios.post<any>("/connect", connectionProperties))
      .data;
  }

  async sendCustomMessages(connectionProperties: any): Promise<any> {
    return (
      await linkedinAxios.post<any>("/custom-messages", connectionProperties)
    ).data;
  }
}

const linkedinService = new LinkedinService();
export default linkedinService;
