import { View } from "@react-pdf/renderer";
import { BreakLine } from "./Components";
import { horizontalPadding, perelynRed, verticalPadding } from "./const";
import { SmallTextPDF, TinyTextPDF } from "./Texts";

export default function Footer() {
  return (
    <View
      fixed
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "91px",
      }}
    >
      <SmallTextPDF
        style={{
          padding: `${verticalPadding} ${horizontalPadding}`,
          color: perelynRed,
        }}
        render={({
          pageNumber,
          totalPages,
        }: {
          pageNumber: number;
          totalPages: number;
        }) => (totalPages - pageNumber === 0 ? "" : `Continues on next page`)}
      />
      <BreakLine />
      <View
        style={{
          padding: `${verticalPadding} ${horizontalPadding}`,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <TinyTextPDF>Page </TinyTextPDF>
        <TinyTextPDF
          render={({ pageNumber }: { pageNumber: number }) => `${pageNumber}`}
        />
      </View>
    </View>
  );
}
