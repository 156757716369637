import { Stack, SxProps } from "@mui/material";
import { useState } from "react";
import {
  borderOrange,
  perelynLightRed,
  taskCheckbox,
} from "../../../styles/consts";
import { getForceSize } from "../../styles/Form.styles";
import Icon from "./Icon";

export default function Checkbox({
  id,
  sx,
  iconName,
  isChecked = false,
  onChange,
}: {
  id?: string;
  sx?: SxProps;
  iconName: string;
  isChecked: boolean;
  onChange?: (c?: any) => void;
}) {
  const [c, setC] = useState(isChecked);

  const handleClick = () => {
    setC((prev) => !prev);
    onChange && onChange(!c);
  };

  return (
    <Stack
      id={id}
      onClick={handleClick}
      sx={{
        ...getForceSize(taskCheckbox),
        border: borderOrange,
        cursor: "crosshair",
        transition: "all .4s",
        "&: hover": {
          backgroundColor: perelynLightRed,
        },
        ...sx,
      }}
    >
      {c && <Icon sx={{ height: "100%", width: "100%" }} name={iconName} />}
    </Stack>
  );
}
