import { t } from "i18next";
import { User } from "./common/models/Models";

export const convertToJSON = (result: string) =>
  JSON.parse(result.replaceAll("True", "true").replaceAll("False", "false"));

export const convertToDate = (timestamp: string) =>
  new Date(parseInt(timestamp) * 1000).toLocaleString("de-DE");

export const getDaysLeft = (trialExpiryDate?: string) => {
  if (trialExpiryDate) {
    const today = new Date();
    const expiryDate = new Date(parseInt(trialExpiryDate) * 1000);
    if (today > expiryDate) return 0;
    const diffDays = Math.round(
      Math.abs((expiryDate as any) - (today as any)) / (1000 * 60 * 60 * 24)
    );
    return diffDays;
  }
  return 0;
};

export const addFederatedToUrl = (url: string) => {
  const federetaedUrl = url + "?federated=true";
  return federetaedUrl;
};

export const convertToTableDate = (timestamp: string) => {
  const date = new Date(parseInt(timestamp) * 1000);
  return `${getWithLeadingZero(date.getHours())}:${getWithLeadingZero(
    date.getMinutes()
  )} - ${getWithLeadingZero(date.getDate())}.${getWithLeadingZero(
    date.getMonth() + 1
  )}.${date.getFullYear()}`;
};

export const formatDateFromPostgres = (
  dateString: string,
  time: boolean = false
) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  if (time) {
    return `${day}-${month}-${year} - ${hours}:${minutes}`;
  } else {
    return `${day}-${month}-${year}`;
  }
};

export const formatDateFromIsoToIsoWithTimezone = (
  dateString: string
): string => {
  return `${dateString} 00:00:00+00`;
};

export const convertToFullSearchDate = (timestamp: string) => {
  const date = new Date(parseInt(timestamp) * 1000);
  const month = date.toLocaleString("default", {
    month: "long",
  });
  return `${getWithLeadingZero(date.getHours())}:${getWithLeadingZero(
    date.getMinutes()
  )} - ${date.getDate()} ${month} ${date.getFullYear()}`;
};

const getWithLeadingZero = (number: number) =>
  number > 9 ? number : "0" + number;

export const capitalizeFirstLetter = (text: string) => {
  if (typeof text !== "string") {
    console.error(
      "capitalizeFirstLetter: expected a string but received:",
      text
    );
    return "";
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const smallFirstLetter = (text: string) =>
  text.charAt(0).toLowerCase() + text.slice(1);

export const replaceUnderscoreForWhitespace = (text: string) =>
  text.replace("_", " ");

export const replaceWhitespaceForUnderscore = (text: string) =>
  text.replace(" ", "_");

export const convertToMMSS = (timeInSeconds: string) => {
  if (!timeInSeconds) {
    return "0:00";
  }
  const date = new Date(parseInt(timeInSeconds) * 1000);
  return `${date.getMinutes()}:${getWithLeadingZero(date.getSeconds())}`;
};

export const convertToHHMM = (timeInSeconds: string) => {
  return timeInSeconds
    ? new Date(parseInt(timeInSeconds) * 1000).toISOString().slice(11, 16)
    : "00:00";
};

export const convertToMinSec = (timeInSeconds: string) => {
  if (!timeInSeconds) return "0 min 0 sec";
  const date = new Date(parseInt(timeInSeconds) * 1000);
  return `${date.getMinutes()} min ${date.getSeconds()} sec`;
};

export function formatMinutesToHoursAndMinutes(minutes: number): string {
  if (minutes < 0) {
    throw new Error("Input must be a non-negative number of minutes");
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.floor(minutes % 60);

  const hoursString = hours > 0 ? `${hours}h ` : "";
  const minutesString = remainingMinutes > 0 ? `${remainingMinutes}min` : "";

  if (hours > 0 && remainingMinutes > 0) {
    return `${hoursString}${minutesString}`;
  } else if (hours > 0) {
    return hoursString;
  } else {
    return minutesString;
  }
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getTimeLeftInSeconds = (ttl?: string) => {
  if (ttl) {
    const now = new Date();
    const deleteDate = new Date(parseInt(ttl) * 1000);
    const difference = (deleteDate.getTime() - now.getTime()) / 1000;
    return difference;
  }
};

export const getTimeLeftHHMM = (difference?: number | string) => {
  if (difference) {
    if (typeof difference === "string") difference = parseInt(difference);
    return new Date(difference * 1000).toISOString().slice(11, 16);
  } else {
    return "00:00";
  }
};

export const getConsultationStatusLabel = (
  consultationStatus: string,
  consultationType: string
) => {
  switch (consultationStatus) {
    case "DONE":
      return "Done";
    default:
      if (consultationType === ANAMNESIS) {
        return t("finishAnamnesis");
      } else {
        return t("finishSummary");
      }
  }
};

export const ANAMNESIS = "Anamnesis";

export const getValueOrEmpty = (value?: string) => {
  return value ? value : "";
};

export const DEFAULT_BOX_SHADOW = {
  boxShadow: "0 1rem 1rem rgba(0, 0, 0, 0.075)",
};

export const downloadConsentForm = () => {
  fetch(
    "https://adiu-consent.s3.eu-central-1.amazonaws.com/Adiu-Einwilligungserklaerung.pdf",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    }
  )
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Adiu-Einwilligungserklaerung.pdf`);

      document.body.appendChild(link);

      link.click();

      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    });
};

export const downloadAdiuBestPractice = () => {
  fetch(
    "https://adiu-consent.s3.eu-central-1.amazonaws.com/Adiu_Best_Practice.pdf",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    }
  )
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Adiu_Best_Practice.pdf`);

      document.body.appendChild(link);

      link.click();

      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    });
};

export const delay = (seconds: number) =>
  new Promise((res) => setTimeout(res, seconds * 1000));

export const getUserInitials = (user: User) => {
  let l: any;
  if (user && user.name) {
    l = user.name.split(" ");
    if (l && l[0] && l[1]) {
      return l[0][0] + l[1][0];
    } else {
      return l[0][0];
    }
  } else {
    l = user.email.split("@")[0];
    if (l && l.includes(".")) {
      l = l.split(".");
    }
  }
  if (l && l[0] && l[1]) {
    return l[0][0] + l[1][0];
  }
};

export const splitMail = (mail: string) => {
  const localPart = mail.split("@")[0];
  if (localPart.length > 16) {
    if (!localPart.includes(".")) {
      return `${localPart.slice(0, 14)}...`;
    } else {
      const parts = localPart.split(".");
      if (parts.length > 1) {
        const firstPart = parts[0];
        if (firstPart.length > 16) {
          return `${firstPart.slice(0, 14)}...`;
        }
        return `${firstPart}.${parts[1][0]}`;
      }
    }
  }
  return localPart;
};
export const getFormattedName = (name: string) => {
  if (name.length > 12) {
    const parts = name.split(" ");
    if (parts.length > 1) {
      const firstPart = parts[0];
      if (firstPart.length > 12) {
        return `${firstPart.slice(0, 10)}...`;
      }
      if (parts.length > 2) {
        const secondPart = parts[1];
        const combinedLength = firstPart.length + secondPart.length + 1;
        if (combinedLength <= 10) {
          const remainingLength = 12 - combinedLength - 1;
          const thirdPart = parts[2];
          if (thirdPart.length > remainingLength) {
            return `${firstPart} ${secondPart} ${thirdPart.slice(
              0,
              remainingLength
            )}...`;
          }
          return `${firstPart} ${secondPart} ${thirdPart}`;
        }
      }
      return `${firstPart} ${parts[1][0]}.`;
    } else {
      return `${name.slice(0, 10)}...`;
    }
  }
  return name;
};

export const removeNumbersFromString = (str: string) => {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
  const lastPart = str.split("/").pop();
  if (lastPart && uuidRegex.test(lastPart)) {
    return str.split("/").slice(0, -1).join("/") + "/:id";
  } else {
    const result = str.replace(/[0-9]/g, "");
    return /\d/.test(str) ? result + ":createdTimestamp" : result;
  }
};

export const removeTrailingSlash = (str: string) => {
  if (str.endsWith("/")) {
    return str.slice(0, -1);
  }
  return str;
};

export const simpleObjectsCompareDifferent = (obj1: any, obj2: any) => {
  return JSON.stringify(obj1) !== JSON.stringify(obj2);
};

export const objectMap = (obj: any, fn: any) =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

export const parseIntOrZero = (value: string) => {
  return parseInt(value) || 0;
};

export const wait = (seconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

export const formatUnixTimestamp = (timestamp: string): string => {
  const date = new Date(parseInt(timestamp) * 1000);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${hours}:${minutes} - ${day}.${month}.${year}`;
};

export const getItemsFromIndex = <T>(
  items: T[],
  startIndex: number,
  count: number
): T[] => {
  return items.slice(startIndex, startIndex + count);
};

export const getNestedProperty = <T>(obj: T, path: string): any => {
  return path.split(".").reduce((acc, part) => {
    if (Array.isArray(acc)) {
      return acc.length > 0
        ? acc.reduce((max, item) => (item[part] > max[part] ? item : max))[part]
        : undefined;
    }
    return acc && acc[part];
  }, obj);
};

export const getByPath = (obj, path) => {
  if (!path || path.length === 0) return undefined;
  if (Array.isArray(path)) path = path.join(".");
  const exactPath = path.replace(/\[|\]|\./g, "").split("");
  const value = exactPath.reduce(
    (source, key) => (source ? source[key] : undefined),
    obj
  );
  return value !== undefined ? value : undefined;
};

export const flip = (setFn) => () => setFn((prev) => !prev);

export const getFirstPathPart = (path: string) => {
  const parts = path.split("/").filter(Boolean);
  return `/${parts.slice(0, 1).join("/")}`;
};

export const roundToTwoDecimals = (num: number) => {
  return Math.ceil(num * 100) / 100;
};

export const getCurrentEnv = () => {
  return process.env.REACT_APP_ENV;
};
