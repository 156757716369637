import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { borderStyle, boxHeight, defaultGap } from "../../../styles/consts";
import { getItemsFromIndex, getNestedProperty } from "../../../utils";
import { Option } from "../fundamentals/Dropdown";
import Loader from "../fundamentals/Loader/Loader";
import FilteringArea from "./FilteringArea";
import LabelRow from "./LabelRow";
import PageNavigation from "./PageNavigation";
import Row, { ItemRow } from "./Row";

export type Sort = {
  param: string;
  order: "asc" | "desc";
};

export default function Table<T>({
  items,
  filterFunction,
  getItemRow,
  filters,
  idProperty,
  deleteAllFunction,
  defaultSort,
  deletable = true,
  filteringArea,
  saveSortName,
  savePageNum,
  loading = false,
  visibleRowsNo = 7,
  additionalOnCheckboxClick,
  callOnNextPage,
}: {
  items: T[];
  filterFunction?: (value: string) => T[];
  getItemRow: (item: T) => ItemRow[];
  filters?: Option[];
  idProperty: string;
  deleteAllFunction?: (ids: string[]) => void;
  defaultSort?: Sort;
  deletable?: boolean;
  filteringArea?: JSX.Element;
  saveSortName?: string;
  savePageNum?: string;
  loading?: boolean;
  visibleRowsNo?: number;
  additionalOnCheckboxClick?: (item: T) => void;
  callOnNextPage?: () => void;
}) {
  const savedPage = savePageNum ? localStorage.getItem(savePageNum) : null;
  const [page, setPage] = useState(savedPage ? parseInt(savedPage) : 1);
  const [filteredItems, setFilteredItems] = useState<T[]>(items);
  const [currentlyDisplayedItems, setCurrentlyDisplayedItems] = useState<T[]>(
    []
  );
  const [selectedItemIds, setselectedItemIds] = useState<string[]>([]);
  const [currentSort, setCurrentSort] = useState<Sort>(
    defaultSort || {
      param: idProperty,
      order: "desc",
    }
  );
  const labels = getItemRow({} as T);
  const handleCheckboxClick = (item: T) => {
    const itemId = item[idProperty];
    setselectedItemIds((prev) => {
      if (selectedItemIds.includes(itemId)) {
        return prev.filter((id) => id !== itemId);
      } else {
        return [...prev, itemId];
      }
    });
    additionalOnCheckboxClick && additionalOnCheckboxClick(item);
  };

  const changeSort = (param?: string) => {
    if (!param) return;

    setCurrentSort((prev): Sort => {
      const sort: Sort = {
        param,
        order: prev.param === param && prev.order === "asc" ? "desc" : "asc",
      };
      if (saveSortName) {
        localStorage.setItem(saveSortName, JSON.stringify(sort));
      }
      return sort;
    });
  };

  useEffect(() => {
    const getSortedItems = (items: T[]) => {
      return items.sort((a, b) => {
        let aVal = getNestedProperty(a, currentSort.param);
        let bVal = getNestedProperty(b, currentSort.param);

        if (aVal === undefined && bVal !== undefined) {
          return currentSort.order === "asc" ? -1 : 1;
        } else if (aVal !== undefined && bVal === undefined) {
          return currentSort.order === "asc" ? 1 : -1;
        } else if (aVal === undefined && bVal === undefined) {
          return 0;
        }

        if (currentSort.order === "asc") {
          return aVal > bVal ? 1 : -1;
        } else {
          return aVal < bVal ? 1 : -1;
        }
      });
    };

    setCurrentlyDisplayedItems(
      getItemsFromIndex(
        getSortedItems(filteredItems),
        (page - 1) * visibleRowsNo,
        visibleRowsNo
      )
    );
  }, [items, page, filteredItems, currentSort]);

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  const handleAllDisplayedSelect = () => {
    if (selectedItemIds.length > 0) {
      setselectedItemIds([]);
    } else {
      setselectedItemIds(
        currentlyDisplayedItems.map((item) => item[idProperty])
      );
    }
  };

  return (
    <Stack>
      <Stack
        sx={{
          padding: `${defaultGap} 0 0.75rem ${defaultGap}`,
          borderBottom: borderStyle,
          boxSizing: "border-box",
        }}
      >
        {filterFunction && filters && (
          <FilteringArea
            deletable={deletable}
            deleteAllFunction={() => {
              if (deleteAllFunction) {
                deleteAllFunction(selectedItemIds);
              }
            }}
            filterFunction={filterFunction}
            setFilteredItems={setFilteredItems}
            setPage={setPage}
            filters={filters}
            anySelected={selectedItemIds.length > 0}
            items={items}
            filteringArea={filteringArea}
          />
        )}

        <LabelRow
          labels={labels}
          isChecked={selectedItemIds.length > 0}
          handleAllDisplayedSelect={handleAllDisplayedSelect}
          currentSort={currentSort}
          changeSort={changeSort}
        />
      </Stack>
      <Stack
        sx={{
          width: "100%",
          minHeight: `calc(${boxHeight} * ${visibleRowsNo})`,
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          currentlyDisplayedItems.map((item, idx) => (
            <Row
              key={idx}
              itemRow={getItemRow(item)}
              handleCheckboxClick={() => handleCheckboxClick(item)}
              isChecked={selectedItemIds.includes(item[idProperty])}
            />
          ))
        )}
      </Stack>
      <PageNavigation
        page={page}
        setPage={setPage}
        filteredItems={filteredItems}
        visibleRowsNo={visibleRowsNo}
        callOnNextPage={callOnNextPage}
        storageKey={savePageNum}
      />
    </Stack>
  );
}
