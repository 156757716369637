import { postgresApiAxios } from "./axios";

class AlqemiPostgresService {
  async getOffers(filter?: string | null): Promise<any> {
    return (
      await postgresApiAxios.get<any>(
        `/offers${filter ? "?filter=" + filter : ""}`
      )
    ).data;
  }

  async getOffer(id: string): Promise<any> {
    return (await postgresApiAxios.get<any>("/offer/" + id)).data;
  }

  async getCandidates(): Promise<any> {
    return (await postgresApiAxios.get<any>("/candidates")).data;
  }

  async updateCandidateStatus(
    id: string,
    status: string,
    fromDate?: string
  ): Promise<any> {
    const payload: any = { id, status };
    if (fromDate) {
      payload.from_date = fromDate;
    }
    return (
      await postgresApiAxios.post<any>("/update-candidate-status", payload)
    ).data;
  }

  async updateMatch(id: string, property: string, value: any): Promise<any> {
    const payload: any = { id, property, value };
    return (await postgresApiAxios.post<any>("/update-match", payload)).data;
  }

  async getMatch(
    id: string,
    previousUpdatedAt: string
  ): Promise<{ data: any; status: string }> {
    const response = await postgresApiAxios.get<any>(
      `/match/${id}?previousUpdatedAt=${encodeURIComponent(previousUpdatedAt)}`
    );
    return response.data;
  }
}

const alqemiPostgresService = new AlqemiPostgresService();

export default alqemiPostgresService;
