import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import alqemiPostgresService from "../../../../api/PostgresService";
import SearchBar from "../../../../common/components/layout/SearchBar";
import { ItemRow } from "../../../../common/components/table/Row";
import Table from "../../../../common/components/table/Table";
import { Offer } from "../../../../common/models/Models";
import {
  FullStack,
  RatingScoreBox,
} from "../../../../common/styles/Box.styles";
import { PrimaryButton } from "../../../../common/styles/Button.styles";
import {
  hideScrollbar,
  smallerGap,
  smallGap,
  tinyGap,
} from "../../../../styles/consts";
import { formatDateFromPostgres } from "../../../../utils";
import OfferStatus from "../../../Offer/components/OfferStatus";
import { useAlqemiDashboard } from "../../../Offer/context/AlqemiDashboardProvider";
import CandidatesList from "./CandidatesList";
import ConfigButtons from "./ConfigButtons";

const saveSortName = "offersSorting";
const savePageNum = "alqemiOffersPage";

export default function AlqemiDashboard({ basePath }) {
  const navigate = useNavigate();
  const {
    offers,
    setOffers,
    candidates,
    showOnlyTopOffers,
    setShowOnlyTopOffers,
  } = useAlqemiDashboard();
  const previouslySavedSorting = localStorage.getItem(saveSortName);
  const defaultSorting = previouslySavedSorting
    ? JSON.parse(previouslySavedSorting)
    : {
        param: "created_at",
        order: "desc",
      };
  const [loadingData, setLoadingData] = useState(false);
  const [searchKey, setSearchKey] = useState(0);

  const handleGoToOffer = async (offer: Offer) => {
    navigate(`${basePath}/${offer.id}`);
  };

  const getOfferRow = (offer: Offer): ItemRow[] => {
    function ClickableStack({
      children,
    }: {
      children: JSX.Element[] | JSX.Element;
    }) {
      // Create the URL for the offer
      const offerUrl = `${basePath}/${offer.id}`;

      return (
        <a
          href={offerUrl}
          onClick={(e) => {
            e.preventDefault();
            handleGoToOffer(offer);
          }}
          target="_self"
          rel="noopener noreferrer"
          style={{
            textDecoration: "none",
            color: "inherit",
            display: "flex",
            width: "100%",
          }}
        >
          <Stack
            sx={{
              width: "100%",
              height: "100%",
              cursor: "crosshair",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {children}
          </Stack>
        </a>
      );
    }

    return [
      {
        sortParam: "title",
        label: "Offer",
        component: (
          <ClickableStack>
            <Typography>{offer.title || "Offer title"}</Typography>
          </ClickableStack>
        ),
        width: "35%",
        minWidth: "7rem",
      },
      {
        sortParam: "status",
        label: "Status",
        component: (
          <ClickableStack>
            <OfferStatus offer={offer} />
          </ClickableStack>
        ),
        width: "15%",
        minWidth: "6rem",
      },
      {
        sortParam: "matches.score",
        label: "Candidate's rating",
        component: (
          <ClickableStack>
            <Stack sx={{ gap: tinyGap }}>
              {offer.matches &&
                offer.matches.map((match, idx) => {
                  const candidate = candidates.find(
                    (c) => c.consultant_id === match.consultant_id
                  ) || { name: "" };
                  return (
                    <Stack
                      key={idx}
                      sx={{
                        flexDirection: "row",
                        "@media (min-width: 1000px) and (max-width: 1300px)": {
                          width: "70%",
                        },
                        "@media (min-width: 1300px) and (max-width: 2300px)": {
                          width: "60%",
                        },
                        "@media (min-width: 2300px)": {
                          width: "40%",
                        },
                        maxGap: tinyGap,
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>{candidate.name}</Typography>
                      <RatingScoreBox>{match.score}</RatingScoreBox>
                    </Stack>
                  );
                })}
            </Stack>
          </ClickableStack>
        ),
        width: "25%",
        minWidth: "6rem",
      },
      {
        sortParam: "created_at",
        label: "Date scraped",
        component: (
          <ClickableStack>
            <Typography>
              {formatDateFromPostgres(offer.created_at, true)}
            </Typography>
          </ClickableStack>
        ),
        width: "20%",
        minWidth: "6rem",
      },
      {
        component: (
          <Stack
            sx={{
              flexDirection: "row",
              gap: smallerGap,
            }}
          >
            {/* <BiggerIcon
              name="Trash"
              // onClick={() => withLoading(() => handleOfferDelete(offer))}
              sx={{
                cursor: "crosshair",
              }}
            /> */}
          </Stack>
        ),
        width: "5%",
        minWidth: "4rem",
      },
    ];
  };

  return (
    <FullStack
      sx={{
        overflow: "auto",
        ...hideScrollbar,
      }}
    >
      <SearchBar
        type="offers"
        onSearch={async (searchQuery) => {
          const results = await alqemiPostgresService.getOffers(searchQuery);
          localStorage.setItem(savePageNum, "1");
          setOffers(results);
          setSearchKey((prev) => prev + 1);
        }}
        setLoadingData={setLoadingData}
      />
      <Table
        key={searchKey}
        filters={[
          { value: "All" },
          { value: "viewed" },
          { value: "completed", name: "New" },
          { value: "sent" },
        ]}
        items={offers}
        idProperty="url"
        deleteAllFunction={() => {}}
        filterFunction={(value) => {
          if (value === "All") {
            return offers;
          }
          return offers.filter((offer) => {
            return (
              offer.matches.find((match) => match.status)?.status ===
              value.toLowerCase()
            );
          });
        }}
        getItemRow={getOfferRow}
        defaultSort={defaultSorting}
        saveSortName={saveSortName}
        savePageNum={savePageNum}
        loading={loadingData}
        filteringArea={
          <Stack sx={{ flexDirection: "row", gap: smallGap }}>
            <CandidatesList />

            <PrimaryButton
              id={"top-offers-button"}
              onClick={async () => {
                setLoadingData(true);
                localStorage.setItem(
                  "alqemiFilter",
                  showOnlyTopOffers ? "" : "best"
                );
                localStorage.setItem(savePageNum, "1");
                setSearchKey((prev) => prev + 1);
                setShowOnlyTopOffers((prev: boolean) => !prev);
                setOffers(
                  await alqemiPostgresService.getOffers(
                    showOnlyTopOffers ? null : "best"
                  )
                );
                setLoadingData(false);
              }}
            >
              {showOnlyTopOffers ? "Show all offers" : "Show only top matches"}
            </PrimaryButton>
            <ConfigButtons />
          </Stack>
        }
      />
    </FullStack>
  );
}
