import { useContext } from "react";
import { DefaultPage, RedSlash } from "./Components";
import { CVContentContext } from "./CVPDF";
import { ContentRow, HeaderRow, Table } from "./TableComponents";
import { SmallTextPDF } from "./Texts";

export default function Tech() {
  const cvContent = useContext<any>(CVContentContext);
  return (
    <DefaultPage>
      <Table>
        <HeaderRow label="Industry & Technical Expertise" />
        {Array.isArray(cvContent["tech"]) &&
          cvContent["tech"]
            .sort((tech) => (Object.keys(tech)[0] === "Industries" ? -1 : 1))
            .map((tech, techIndex) => {
              const firstKey = Object.keys(tech)[0];
              const values = tech[firstKey];
              return (
                <ContentRow
                  key={firstKey}
                  label={firstKey}
                  content={values.map((value, index) => (
                    <SmallTextPDF key={index}>
                      {value}
                      {index < values.length - 1 && <RedSlash />}
                    </SmallTextPDF>
                  ))}
                  last={techIndex === cvContent["tech"].length - 1}
                />
              );
            })}
      </Table>
    </DefaultPage>
  );
}
