import { Task } from "../common/models/Models";
import { apiAxios } from "./axios";

class CampaignService {
  async getProfiles(): Promise<any> {
    return (await apiAxios.get<any>("/campaign-scraped-profiles")).data;
  }

  async updateProfile(
    name: string,
    profilePropertiesToUpdate: any
  ): Promise<any> {
    return (
      await apiAxios.put<Task>(
        `/campaign-scraped-profile/${name}`,
        profilePropertiesToUpdate
      )
    ).data;
  }

  async getMessages(): Promise<any> {
    return (await apiAxios.get<any>("/campaign-messages")).data;
  }

  async createMessage(message: any): Promise<any> {
    return (await apiAxios.post<any>("/campaign-message", message)).data;
  }
}

const campaignService = new CampaignService();
export default campaignService;
