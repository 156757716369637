import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import linkedinService from "../../../api/LinkedinService";
import Checkbox from "../../../common/components/fundamentals/Checkbox";
import Loader from "../../../common/components/fundamentals/Loader/Loader";
import SearchBar from "../../../common/components/layout/SearchBar";
import Table from "../../../common/components/table/Table";
import { HeadingText, MediumText } from "../../../common/styles/Text.styles";
import { defaultGap, smallGap } from "../../../styles/consts";
import { convertToDate } from "../../../utils";
import Messaging from "./Messaging";

export default function ConnectionsAndMessages({ refreshers, icp = false }) {
  const [connections, setConnections] = useState<any[]>([]);
  const [selectedConnections, setSelectedConnections] = useState<any[]>([]);
  const [messagingResponse, setMessagingResponse] = useState<any>();
  const [sendingMessages, setSendingMessages] = useState(false);
  const [loadingConnections, setLoadingConnections] = useState(true);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any>();
  const [isCustomSearching, setIsCustomSearching] = useState(false);

  const getConnections = async () => {
    setLoadingConnections(true);
    const params: any[] = [];
    if (lastEvaluatedKey) {
      params.push(lastEvaluatedKey);
    }
    if (icp) {
      params.push({
        key: "icp",
        value: "true",
      });
    }
    const response = await linkedinService.getConnections(params);
    setConnections((prev) => {
      return prev.concat(response["Items"]);
    });
    if (response["LastEvaluatedKey"]) {
      setLastEvaluatedKey({
        key: "lastEvaluatedKey",
        value: response["LastEvaluatedKey"]["name"],
      });
    }
    setLoadingConnections(false);
  };

  useEffect(() => {
    getConnections();
  }, refreshers);

  const addOrRemoveSelectedConnection = (connection) => {
    setSelectedConnections((prev) => {
      const index = prev.findIndex((item) => {
        if (item.urn_id && connection.urn_id) {
          return item.urn_id === connection.urn_id;
        } else {
          return item.profile_id === connection.profile_id;
        }
      });
      if (index === -1) {
        return [
          ...prev,
          {
            urn_id: connection.urn_id,
            profile_id: connection.profile_id,
            name: connection.name,
          },
        ];
      } else {
        return prev.filter((item) => {
          if (item.urn_id && connection.urn_id) {
            return item.urn_id !== connection.urn_id;
          } else {
            return item.profile_id !== connection.profile_id;
          }
        });
      }
    });
  };

  const sendMessages = async (message: string) => {
    setSendingMessages(true);
    try {
      const body = {
        connections: selectedConnections,
        username: "maksymiliankalek",
        message,
      };
      setMessagingResponse(await linkedinService.sendMessages(body));
      localStorage.setItem("linkedin_message", message);
    } finally {
      setSendingMessages(false);
    }
  };

  const getConnectionRow = (connection) => {
    const handleCheckboxClick = async (c) => {
      await linkedinService.updateMonitorCareerChange({
        name: connection.name,
        monitorCareerChanges: c,
      });
    };

    const handleICPCheckboxClick = async (c) => {
      console.log(c);
      await linkedinService.updateICPStatus({
        name: connection.name,
        ICP: c,
      });
    };

    const columns = [
      {
        sortParam: "name",
        label: "Name",
        component: (
          <Typography
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              window.open(
                `https://www.linkedin.com/in/${
                  connection.urn_id ? connection.urn_id : connection.profile_id
                }/`,
                "_blank"
              );
            }}
          >
            {connection.name}
          </Typography>
        ),
        width: "15%",
      },
      {
        sortParam: "jobtitle",
        label: "Title",
        component: <Typography>{connection.jobtitle}</Typography>,
        width: "20%",
      },
      {
        sortParam: "company",
        label: "Company",
        component: <Typography>{connection.company}</Typography>,
        width: "10%",
      },
      {
        sortParam: "accepted",
        label: "Accepted",
        component: <Typography>{connection.accepted?.toString()}</Typography>,
        width: "10%",
      },
      {
        sortParam: "connectedBy",
        label: "Connected by",
        component: <Typography>{connection.connectedBy}</Typography>,
        width: "15%",
        forceAlignLeft: true,
      },
    ];

    if (icp) {
      columns.push(
        {
          sortParam: "monitorCareerChanges",
          label: "Monitor career changes",
          component: (
            <Checkbox
              iconName="WhiteCheck"
              onChange={handleCheckboxClick}
              isChecked={connection.monitorCareerChanges}
            />
          ),
          width: "10%",
          forceAlignLeft: true,
        },
        {
          sortParam: "careerChange",
          label: "Latest career change",
          component: (
            <Typography>{`${connection?.careerChange?.previousTitle} (${
              connection?.careerChange?.previousCompany
            }) -> ${connection?.careerChange?.newTitle} (${
              connection?.careerChange?.newCompany
            }) {at ${convertToDate(
              connection?.careerChange?.timestamp
            )}}`}</Typography>
          ),
          width: "15%",
          forceAlignLeft: true,
        }
      );
    } else {
      columns.push({
        sortParam: "ICP",
        label: "ICP",
        component: (
          <Checkbox
            iconName="WhiteCheck"
            onChange={handleICPCheckboxClick}
            isChecked={connection.ICP}
          />
        ),
        width: "10%",
        forceAlignLeft: true,
      });
    }
    return columns;
  };

  return (
    <Stack
      sx={{
        gap: smallGap,
        padding: defaultGap,
      }}
    >
      <HeadingText>Connections</HeadingText>
      <Messaging
        sendMessages={sendMessages}
        sendingMessages={sendingMessages}
      />
      {messagingResponse && (
        <Stack>
          <MediumText>Response</MediumText>
          <pre style={{ whiteSpace: "pre-wrap" }}>
            {JSON.stringify(messagingResponse, null, 2)}
          </pre>
        </Stack>
      )}
      <SearchBar
        type="Connections"
        onSearch={async (searchQuery) => {
          if (searchQuery) {
            setIsCustomSearching(true);
            setLastEvaluatedKey(undefined);
            const params: any[] = [
              {
                key: "searchQuery",
                value: searchQuery,
              },
            ];
            if (icp) {
              params.push({
                key: "icp",
                value: "true",
              });
            }
            setConnections(
              (await linkedinService.getConnections(params))["Items"]
            );
          } else {
            setIsCustomSearching(false);
          }
        }}
        setLoadingData={setLoadingConnections}
      />
      {/* <LinkedinConnection
        connection={{
          location: "Poznań, Greater Poland District, Poland",
          distance: "DISTANCE_1",
          connectedBy: "maksymiliankalek",
          accepted: true,
          createdTimestamp: 1741177794,
          name: "Klaudia Sokołowska",
          profile_id: "klaudia-sokołowska-03258a234",
          jobtitle: "Tester",
        }}
        addOrRemoveSelectedConnection={addOrRemoveSelectedConnection}
      /> */}
      {loadingConnections ? (
        <Loader />
      ) : connections.length > 0 ? (
        <Table
          items={connections}
          getItemRow={getConnectionRow}
          idProperty="name"
          visibleRowsNo={50}
          additionalOnCheckboxClick={addOrRemoveSelectedConnection}
          callOnNextPage={isCustomSearching ? () => {} : getConnections}
        />
      ) : (
        <Typography>No connections</Typography>
      )}
    </Stack>
  );
}
