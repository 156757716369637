import { Stack } from "@mui/material";
import { useState } from "react";
import {
  default as alqemiPostgresService,
  default as PostgresService,
} from "../../../../api/PostgresService";
import Dropdown from "../../../../common/components/fundamentals/Dropdown";
import { BiggerIcon } from "../../../../common/components/fundamentals/Icon";
import { useSnackbar } from "../../../../common/context/SnackbarProvider";
import { PrimaryButton } from "../../../../common/styles/Button.styles";
import {
  borderStyle,
  defaultTransition,
  iconGap,
  smallerGap,
  smallGap,
} from "../../../../styles/consts";
import { formatDateFromIsoToIsoWithTimezone } from "../../../../utils";
import { useAlqemiDashboard } from "../../../Offer/context/AlqemiDashboardProvider";
import PopUpBox from "../../MarketingTextTool/components/result/PopUpBox";
import CandidateCard from "./CandidateCard";

export default function CandidatesList() {
  const { candidates, setCandidates } = useAlqemiDashboard();
  const { showSnackbar } = useSnackbar();
  const [isAdding, setIsAdding] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState<string | null>(
    null
  );
  const [selectedDate, setSelectedDate] = useState<string | null>(null);

  const getCandidateIdToUse = (candidateId: string): string | null => {
    const candidate = candidates.find((c) => c.id === candidateId);
    if (!candidate) {
      showSnackbar("Candidate not found", true, true);
      return null;
    }
    return candidate.consultant_id || candidate.id;
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };

  const handleSave = async () => {
    if (!selectedCandidate) {
      showSnackbar("Please select a candidate from the dropdown", true, true);
      return;
    }
    if (!selectedDate) {
      showSnackbar("Please pick a date", true, true);
      return;
    }
    const idToUse = getCandidateIdToUse(selectedCandidate);
    if (!idToUse) return;

    await alqemiPostgresService.updateCandidateStatus(
      idToUse,
      "active",
      formatDateFromIsoToIsoWithTimezone(selectedDate)
    );

    showSnackbar("Candidate's status updated successfully.", false, true);
    setCandidates(
      candidates.map((c) =>
        c.id === selectedCandidate
          ? { ...c, status: "active", from_date: selectedDate }
          : c
      )
    );
    setIsAdding(false);
    setSelectedCandidate(null);
    setSelectedDate(null);
  };

  const handleEditDate = async (id: string, newDate: string) => {
    if (!newDate) {
      showSnackbar("Please pick a date", true, true);
      return;
    }
    const idToUse = getCandidateIdToUse(id);
    if (!idToUse) return;

    await alqemiPostgresService.updateCandidateStatus(
      idToUse,
      "active",
      formatDateFromIsoToIsoWithTimezone(newDate)
    );

    showSnackbar("Candidate's availability updated successfully!", false, true);
    setCandidates(
      candidates.map((candidate) =>
        candidate.id === id ? { ...candidate, from_date: newDate } : candidate
      )
    );
    setSelectedCandidate(null);
    setSelectedDate(null);
  };

  const handleDelete = async (id: string) => {
    const idToUse = getCandidateIdToUse(id);
    if (!idToUse) return;
    await PostgresService.updateCandidateStatus(idToUse, "inactive");
    showSnackbar("Candidate's status updated to inactive", false, true);
    setCandidates(
      candidates.map((c) => (c.id === id ? { ...c, status: "inactive" } : c))
    );
  };

  return (
    <PopUpBox
      button={
        <PrimaryButton id="scouting-candidates">
          Scouting candidates
        </PrimaryButton>
      }
      heading="Scouting candidates"
      minHeight="50%"
      onClose={() => setIsAdding(false)}
    >
      <Stack
        sx={{
          height: "100%",
          border: borderStyle,
          padding: smallerGap,
          gap: smallGap,
        }}
      >
        {candidates
          .filter((candidate) => candidate.status === "active")
          .map((candidate, idx) => (
            <CandidateCard
              key={idx}
              candidate={candidate}
              handleDelete={handleDelete}
              handleDateChange={handleDateChange}
              handleEditDate={handleEditDate}
              selectedDate={selectedDate || ""}
            />
          ))}
        {isAdding && (
          <Stack
            sx={{
              border: borderStyle,
              width: "20rem",
              padding: iconGap,
              flexDirection: "row",
              gap: smallGap,
            }}
          >
            <Stack
              component={"img"}
              src={selectedCandidate ? "/pfpTest.jpg" : "/pfpTest.jpg"}
              sx={{ border: borderStyle, width: "4.5rem", height: "4.5rem" }}
            />
            <Stack
              sx={{
                flexDirection: "column",
                gap: iconGap,
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Dropdown
                minWidth="100%"
                id="candidate-dropdown"
                initialSelected=""
                options={candidates
                  .filter(
                    (candidate) =>
                      candidate.status === "inactive" || !candidate.status
                  )
                  .map((candidate) => ({
                    value: candidate.id,
                    name: candidate.name,
                    // Store the consultant_id for reference when needed
                    consultantId: candidate.consultant_id || candidate.id,
                  }))}
                onChange={(value: string) => {
                  setSelectedCandidate(value);
                }}
              />
              <Stack
                sx={{
                  width: "100%",
                  height: "fit-content",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "end",
                }}
              >
                <Stack
                  component={"input"}
                  type="date"
                  onChange={handleDateChange}
                  sx={{
                    border: borderStyle,
                    height: "1.25rem",
                    justifyContent: "center",
                  }}
                />

                <PrimaryButton sx={{ height: "1.25rem" }} onClick={handleSave}>
                  Save
                </PrimaryButton>
              </Stack>
            </Stack>
          </Stack>
        )}
        <Stack
          id={"add-candidate"}
          sx={{
            "&&": {
              height: "4.20rem",
              width: "4.20rem",
            },
            border: borderStyle,
            justifyContent: "center",
            alignItems: "center",
            transition: defaultTransition,
            "&:hover": isAdding
              ? {}
              : {
                  filter:
                    "brightness(0) saturate(100%) invert(48%) sepia(27%) saturate(1354%) hue-rotate(315deg) brightness(98%) contrast(107%)",
                  cursor: "crosshair",
                  "& > *": {
                    cursor: "crosshair",
                  },
                },
          }}
          onClick={() => {
            setIsAdding(true);
          }}
        >
          <BiggerIcon
            name="Plus"
            sx={{
              height: "2rem",
              width: "2rem",
              ...(isAdding && {
                filter: "none",
                "&:hover": {
                  filter: "none",
                },
              }),
            }}
          />
        </Stack>
      </Stack>
    </PopUpBox>
  );
}
