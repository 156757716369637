import { Stack } from "@mui/material";
import { JsonEditor } from "json-edit-react";
import alqemiPostgresService from "../../../../api/PostgresService";
import { OutlinedButton } from "../../../../common/styles/Button.styles";
import { borderStyle } from "../../../../styles/consts";
import PopUpBox from "../../MarketingTextTool/components/result/PopUpBox";

export default function CVEdit({ cvContent, displayedMatch, setCvContent }) {
  return (
    <PopUpBox
      button={<OutlinedButton>Edit CV Content</OutlinedButton>}
      heading={"Edit CV Content"}
      width="fit-content"
      height="90%"
    >
      <Stack
        sx={{
          height: "100%",
          width: "fit-content",
          maxWidth: "100%",
          overflow: "auto",
          border: borderStyle,
        }}
      >
        <JsonEditor
          data={cvContent}
          onUpdate={(props) => {
            alqemiPostgresService.updateMatch(
              displayedMatch.id,
              "cv",
              props.newData
            );
            setCvContent(props.newData);
          }}
        />
      </Stack>
    </PopUpBox>
  );
}
