import { useOffer } from "../../../pages/Modules/Alqemi/context/OfferProvider";
import AutoUpdateTextField from "./AutoUpdateTextField";

export default function OfferAutoUpdateTextField({
  defaultValue,
  propPath,
  matchIndex,
  ...props
}: {
  defaultValue: string;
  propPath: string | string[];
  matchIndex: number;
  [key: string]: any;
}) {
  const { saveOffer } = useOffer();

  const saveWithIndexAndProperty = async (value: string) => {
    const actualValue =
      typeof value === "object" ? Object.values(value)[0] : value;
    return saveOffer(actualValue as string, matchIndex, "email");
  };

  return (
    <AutoUpdateTextField
      defaultValue={defaultValue}
      propPath={propPath}
      saveFunction={saveWithIndexAndProperty}
      {...props}
    />
  );
}
