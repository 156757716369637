import { useLocation } from "react-router-dom";
import alqemiPostgresService from "../../../api/PostgresService";
import DataLoadingWrapper from "../../../common/components/utils/DataLoadingWrapper";
import OfferProvider from "./context/OfferProvider";
import AlqemiMatchPage from "./MatchPage/AlqemiMatchPage";

export default function AlqemiWrapper() {
  const location = useLocation();

  const getOfferFromLocation = async () => {
    const id = location.pathname.split("/").at(-1) || "";
    return alqemiPostgresService.getOffer(id);
  };

  return (
    <DataLoadingWrapper
      ContextProvider={OfferProvider}
      fetchFunctions={[getOfferFromLocation]}
      observeParam={location.pathname}
    >
      <AlqemiMatchPage />
    </DataLoadingWrapper>
  );
}
