import AutoUpdateTextField from "../../../../common/components/fundamentals/AutoUpdateTextField";
import { OutlinedButton } from "../../../../common/styles/Button.styles";
import PopUpBox from "../../MarketingTextTool/components/result/PopUpBox";

export default function ModifyAlqemiMatchingConfig({
  type,
  defaultValue,
  propPath,
  saveConfig,
}: {
  type: string;
  defaultValue: any;
  propPath: string;
  saveConfig: any;
}) {
  return (
    <PopUpBox
      button={<OutlinedButton>Modify {type}</OutlinedButton>}
      heading={`Modify ${type} Permanently`}
    >
      <AutoUpdateTextField
        defaultValue={defaultValue}
        propPath={propPath}
        saveFunction={saveConfig}
        multiline
        maxRows={25}
      />
    </PopUpBox>
  );
}
