import { Stack } from "@mui/material";
import { Navigate } from "react-router-dom";
import { defaultLayoutConfig } from "./common/context/LayoutConfigProvider";
import { useUser } from "./common/context/UserProvider";
import ChangelogPage from "./pages/Changelog/ChangelogPage";
import AlqemiWrapper from "./pages/Modules/Alqemi/AlqemiWrapper";
import CampaignDashboard from "./pages/Modules/Campaign/CampaignDashboard";
import ImageGenerationConfigPage from "./pages/Modules/ImageGenerationTool/components/config/ImageGenerationConfigPage";
import ImageGenerationResultsPage from "./pages/Modules/ImageGenerationTool/components/result/ImageGenerationResultsPage";
import ICPDashboard from "./pages/Modules/Linkedin/ICP/ICPDashboard";
import LinkedinDashboard from "./pages/Modules/Linkedin/LinkedinDashboard";
import ArenaWrapper from "./pages/Modules/MarketingTextTool/Arena/ArenaWrapper";
import MarketingTextConfigPage from "./pages/Modules/MarketingTextTool/components/config/MarketingTextConfigPage";
import MarketingTextResultsPage from "./pages/Modules/MarketingTextTool/components/result/MarketingTextResultsPage";
import Wrapper from "./pages/Modules/MarketingTextTool/Wrapper";
import { modulesNames, overviews } from "./pages/Modules/overviews";
import PdfDataExtractionConfigPage from "./pages/Modules/PdfDataExtractionTool/components/config/PdfDataExtractionConfigPage";
import PdfDataExtractionResultsPage from "./pages/Modules/PdfDataExtractionTool/components/result/PdfDataExtractionResultsPage";
import ModulesDashboardPage from "./pages/ModulesDashboard/ModulesDashboardPage";
import AlqemiDashboardWrapper from "./pages/Offer/AlqemiDashboardWrapper";
import OpenWebUI from "./pages/OpenWebUI";
import OverviewPage from "./pages/overview/OverviewPage";
import AdminSettingsWrapper from "./pages/Settings/AdminSettings/AdminSettingsWrapper";
import ParametersSettings from "./pages/Settings/CustomParameters/ParametersSettings";
import PromptsSettings from "./pages/Settings/CustomPrompts/PromptsSettings";
import OrganizationSettings from "./pages/Settings/Organization/OrganizationSettingsPage";
import ChangePassword from "./pages/Settings/Password/ChangePassword";
import PersonalInformationSetting from "./pages/Settings/PersonalInformationSetting";
import SettingsPage from "./pages/Settings/SettingsPage";
import TasksDashboardWrapper from "./pages/Task/TasksDashboardWrapper";
import { removeNumbersFromString } from "./utils";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { user } = useUser();
  if (user.active === false) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export type LayoutConfig = {
  heading: string;
  secondarySidebarVisible?: boolean;
  addToFavoriteVisible?: boolean;
  backToVisible?: boolean;
  useToolVisible?: boolean;
  signUpForNewsVisible?: boolean;
};

type RouteType = {
  path: string;
  component?: JSX.Element;
  children?: RouteType[];
  layoutConfig: LayoutConfig;
};

export const getLayoutConfig = (path: string) => {
  const currentPath = findRouteByPath(path);
  return { ...defaultLayoutConfig, ...currentPath?.layoutConfig };
};

function findRouteByPath(path: string) {
  const pathWithoutSlash = removeNumbersFromString(path);

  return routes.find((route) => {
    return route.path === pathWithoutSlash;
  });
}

const MARKETING_TEXT_HEADING = "Marketing text tool";
const IMAGE_GENERATION_HEADING = "Image generation tool";
const PDF_DATA_EXTRACTION_HEADING = "PDF data extraction tool";
const ALQEMI_HEADING = "Alqemi";
const LINKEDIN_HEADING = "LinkedIn";
const CAMPAIGN_HEADING = "Sales Campaign";
const ICP_HEADING = "ICP";
const OVERVIEW_PAGE_HEADING = "Overview page";

export const MARKETING_TEXT = "marketing-text";
export const IMAGE_GENERATION = "image-generation";
export const PDF_DATA_EXTRACTION = "pdf-data-extraction";
export const ALQEMI = "alqemi";
export const LINKEDIN = "linkedin";
export const CAMPAIGN = "campaign";
export const ICP = "icp";
export const basePath = (type: ModuleName) => "/" + type;
export const dashboardPath = (type: ModuleName) =>
  basePath(type) + "/dashboard";

export type ModuleName =
  | typeof MARKETING_TEXT
  | typeof IMAGE_GENERATION
  | typeof PDF_DATA_EXTRACTION
  | typeof ALQEMI
  | typeof LINKEDIN
  | typeof ICP
  | typeof CAMPAIGN
  | "";

const marketingTextRoutes: RouteType[] = [
  {
    path: dashboardPath(MARKETING_TEXT),
    layoutConfig: {
      heading: MARKETING_TEXT_HEADING,
    },
    component: (
      <ProtectedRoute>
        <TasksDashboardWrapper
          basePath={basePath(MARKETING_TEXT)}
          processStatusParam={"processStatuses.marketingText.status"}
        />
      </ProtectedRoute>
    ),
  },
  {
    path: basePath(MARKETING_TEXT),
    layoutConfig: {
      heading: OVERVIEW_PAGE_HEADING,
      secondarySidebarVisible: false,
      addToFavoriteVisible: true,
      backToVisible: false,
      useToolVisible: true,
    },
    component: <OverviewPage moduleOverview={overviews.marketingText} />,
  },
  {
    path: basePath(MARKETING_TEXT) + "/:createdTimestamp",
    layoutConfig: {
      heading: MARKETING_TEXT_HEADING,
      addToFavoriteVisible: true,
    },
    component: (
      <ProtectedRoute>
        <Wrapper />
      </ProtectedRoute>
    ),
  },
  {
    path: basePath(MARKETING_TEXT) + "/arena/:createdTimestamp",
    layoutConfig: {
      heading: "Arena",
      secondarySidebarVisible: true,
    },
    component: (
      <ProtectedRoute>
        <ArenaWrapper />
      </ProtectedRoute>
    ),
  },
  {
    path: basePath(MARKETING_TEXT) + "/settings",
    layoutConfig: {
      heading: MARKETING_TEXT_HEADING,
    },
    component: (
      <SettingsPage heading="Marketing text settings">
        <PromptsSettings />
      </SettingsPage>
    ),
  },
];

const pdfDataExtractionRoutes: RouteType[] = [
  {
    path: dashboardPath(PDF_DATA_EXTRACTION),
    layoutConfig: {
      heading: PDF_DATA_EXTRACTION_HEADING,
    },
    component: (
      <ProtectedRoute>
        <TasksDashboardWrapper
          basePath={basePath(PDF_DATA_EXTRACTION)}
          processStatusParam={"processStatuses.marketingText.status"}
        />
      </ProtectedRoute>
    ),
  },
  {
    path: basePath(PDF_DATA_EXTRACTION),
    layoutConfig: {
      heading: PDF_DATA_EXTRACTION_HEADING,
      secondarySidebarVisible: false,
      addToFavoriteVisible: true,
      backToVisible: false,
      signUpForNewsVisible: true,
    },
    component: <OverviewPage moduleOverview={overviews.dataExtraction} />,
  },
  {
    path: basePath(PDF_DATA_EXTRACTION) + "/:createdTimestamp",
    layoutConfig: {
      heading: PDF_DATA_EXTRACTION_HEADING,
      addToFavoriteVisible: true,
    },
    component: (
      <ProtectedRoute>
        <Wrapper />
      </ProtectedRoute>
    ),
  },
  {
    path: basePath(PDF_DATA_EXTRACTION) + "/settings",
    layoutConfig: {
      heading: PDF_DATA_EXTRACTION_HEADING,
    },
    component: (
      <SettingsPage heading="Entity extraction settings">
        <ParametersSettings />
      </SettingsPage>
    ),
  },
];

const alqemiRoutes: RouteType[] = [
  {
    path: dashboardPath(ALQEMI),
    layoutConfig: {
      heading: ALQEMI_HEADING,
    },
    component: (
      <ProtectedRoute>
        <AlqemiDashboardWrapper basePath={basePath(ALQEMI)} />
      </ProtectedRoute>
    ),
  },
  {
    path: basePath(ALQEMI),
    layoutConfig: {
      heading: ALQEMI_HEADING,
      secondarySidebarVisible: false,
      addToFavoriteVisible: true,
      backToVisible: false,
      signUpForNewsVisible: true,
    },
    component: <OverviewPage moduleOverview={overviews.alqemi} />,
  },
  {
    path: basePath(ALQEMI) + "/:id",
    layoutConfig: {
      heading: ALQEMI_HEADING,
      addToFavoriteVisible: true,
    },
    component: (
      <ProtectedRoute>
        <AlqemiWrapper />
      </ProtectedRoute>
    ),
  },
  {
    path: basePath(ALQEMI) + "/settings",
    layoutConfig: {
      heading: ALQEMI_HEADING,
    },
    component: (
      <SettingsPage heading="Alqemi settings">
        <Stack />
      </SettingsPage>
    ),
  },
];

const linkedinRoutes: RouteType[] = [
  {
    path: dashboardPath(LINKEDIN),
    layoutConfig: {
      heading: LINKEDIN_HEADING,
    },
    component: (
      <ProtectedRoute>
        <LinkedinDashboard />
      </ProtectedRoute>
    ),
  },
];

const campaignRoutes: RouteType[] = [
  {
    path: dashboardPath(CAMPAIGN),
    layoutConfig: {
      heading: CAMPAIGN_HEADING,
    },
    component: (
      <ProtectedRoute>
        <CampaignDashboard />
      </ProtectedRoute>
    ),
  },
];

const icpRoutes: RouteType[] = [
  {
    path: dashboardPath(ICP),
    layoutConfig: {
      heading: ICP_HEADING,
    },
    component: (
      <ProtectedRoute>
        <ICPDashboard />
      </ProtectedRoute>
    ),
  },
];

const imageGenerationRoutes: RouteType[] = [
  {
    path: basePath(IMAGE_GENERATION),
    layoutConfig: {
      heading: IMAGE_GENERATION_HEADING,
      secondarySidebarVisible: false,
      addToFavoriteVisible: true,
      backToVisible: false,
      signUpForNewsVisible: true,
    },
    component: <OverviewPage moduleOverview={overviews.imageGeneration} />,
  },
  {
    path: dashboardPath(IMAGE_GENERATION),
    layoutConfig: {
      heading: IMAGE_GENERATION_HEADING,
    },
    component: (
      <ProtectedRoute>
        <TasksDashboardWrapper
          basePath={basePath(IMAGE_GENERATION)}
          processStatusParam={"processStatuses.marketingText.status"}
        />
      </ProtectedRoute>
    ),
  },
  {
    path: basePath(IMAGE_GENERATION) + "/:createdTimestamp",
    layoutConfig: {
      heading: IMAGE_GENERATION_HEADING,
      addToFavoriteVisible: true,
    },
    component: (
      <ProtectedRoute>
        <Wrapper />
      </ProtectedRoute>
    ),
  },
];

export const routes: RouteType[] = [
  ...marketingTextRoutes,
  ...imageGenerationRoutes,
  ...pdfDataExtractionRoutes,
  ...alqemiRoutes,
  ...linkedinRoutes,
  ...campaignRoutes,
  ...icpRoutes,
  {
    path: "/",
    component: <ModulesDashboardPage />,
    layoutConfig: {
      heading: "Dashboard",
      secondarySidebarVisible: false,
    },
  },
  {
    path: "/user-settings",
    component: (
      <SettingsPage heading="Account information">
        <PersonalInformationSetting />
        <ChangePassword />
        <OrganizationSettings />
        <PromptsSettings />
      </SettingsPage>
    ),
    layoutConfig: {
      heading: "User settings",
      secondarySidebarVisible: false,
    },
  },
  {
    path: "/user-settings/change-password",
    component: <ChangePassword />,
    layoutConfig: {
      heading: "User settings",
      secondarySidebarVisible: false,
    },
  },
  {
    path: "/open-webui",
    component: <OpenWebUI />,
    layoutConfig: {
      heading: "Fyndr WebUI",
      secondarySidebarVisible: false,
    },
  },
  {
    path: "/admin-settings",
    component: <AdminSettingsWrapper />,
    layoutConfig: {
      heading: "Admin settings",
      secondarySidebarVisible: false,
    },
  },
  {
    path: "/changelog",
    component: <ChangelogPage />,
    layoutConfig: {
      heading: "Changelog",
      secondarySidebarVisible: false,
    },
  },

  {
    path: "/translator",
    layoutConfig: {
      heading: OVERVIEW_PAGE_HEADING,
      secondarySidebarVisible: false,
      addToFavoriteVisible: true,
      backToVisible: false,
      signUpForNewsVisible: true,
    },
    component: <OverviewPage moduleOverview={overviews.translator} />,
  },
  // NEW ROUTES BELOW
  {
    path: "/competitor-analysis",
    layoutConfig: {
      heading: OVERVIEW_PAGE_HEADING,
      secondarySidebarVisible: false,
      addToFavoriteVisible: true,
      backToVisible: false,
      signUpForNewsVisible: true,
    },
    component: <OverviewPage moduleOverview={overviews.competitorAnalysis} />,
  },
  {
    path: "/ai-risk-assessment",
    layoutConfig: {
      heading: OVERVIEW_PAGE_HEADING,
      secondarySidebarVisible: false,
      addToFavoriteVisible: true,
      backToVisible: false,
      signUpForNewsVisible: true,
    },
    component: <OverviewPage moduleOverview={overviews.aiRiskAssessment} />,
  },
  {
    path: "/customer-support",
    layoutConfig: {
      heading: OVERVIEW_PAGE_HEADING,
      secondarySidebarVisible: false,
      addToFavoriteVisible: true,
      backToVisible: false,
      signUpForNewsVisible: true,
    },
    component: <OverviewPage moduleOverview={overviews.customerSupport} />,
  },
  {
    path: "/customer-feedback",
    layoutConfig: {
      heading: OVERVIEW_PAGE_HEADING,
      secondarySidebarVisible: false,
      addToFavoriteVisible: true,
      backToVisible: false,
      signUpForNewsVisible: true,
    },
    component: <OverviewPage moduleOverview={overviews.customerFeedback} />,
  },
  {
    path: "/onboarding",
    layoutConfig: {
      heading: OVERVIEW_PAGE_HEADING,
      secondarySidebarVisible: false,
      addToFavoriteVisible: true,
      backToVisible: false,
      signUpForNewsVisible: true,
    },
    component: <OverviewPage moduleOverview={overviews.onboarding} />,
  },
  {
    path: "/corporate-search",
    layoutConfig: {
      heading: OVERVIEW_PAGE_HEADING,
      secondarySidebarVisible: false,
      addToFavoriteVisible: true,
      backToVisible: false,
      signUpForNewsVisible: true,
    },
    component: <OverviewPage moduleOverview={overviews.corporateSearch} />,
  },
];

export const modulesPages = {
  [MARKETING_TEXT]: {
    CONFIG: <MarketingTextConfigPage />,
    RESULT: <MarketingTextResultsPage />,
  },
  [PDF_DATA_EXTRACTION]: {
    CONFIG: <PdfDataExtractionConfigPage />,
    RESULT: <PdfDataExtractionResultsPage />,
  },
  [IMAGE_GENERATION]: {
    CONFIG: <ImageGenerationConfigPage />,
    RESULT: <ImageGenerationResultsPage />,
  },
};

export const modulesDashboard = [
  {
    tags: overviews.alqemi.tags,
    description: overviews.alqemi.shortDescription,
    heading: modulesNames.alqemi,
    iconName: "GreenCheckSquare",
    path: basePath(ALQEMI),
    id: "alqemi",
  },
  {
    tags: overviews.marketingText.tags,
    description: overviews.marketingText.shortDescription,
    heading: modulesNames.marketingText,
    iconName: "GreenCheckSquare",
    path: basePath(MARKETING_TEXT),
    id: "marketing-text",
    euAiAct: false,
  },
  {
    tags: overviews.dataExtraction.tags,
    description: overviews.dataExtraction.shortDescription,
    heading: modulesNames.dataExtraction,
    iconName: "GreenCheckSquare",
    path: basePath(PDF_DATA_EXTRACTION),
    id: "pdf-data-extraction",
  },

  {
    tags: overviews.linkedin.tags,
    description: overviews.linkedin.shortDescription,
    heading: modulesNames.linkedin,
    iconName: "GreenCheckSquare",
    path: basePath(LINKEDIN),
    id: "linkedin",
  },
  {
    tags: overviews.icp.tags,
    description: overviews.icp.shortDescription,
    heading: modulesNames.icp,
    iconName: "GreenCheckSquare",
    path: basePath(ICP),
    id: "icp",
  },
  {
    tags: overviews.campaign.tags,
    description: overviews.campaign.shortDescription,
    heading: modulesNames.campaign,
    iconName: "GreenCheckSquare",
    path: basePath(CAMPAIGN),
    id: "campaign",
  },
  {
    tags: overviews.imageGeneration.tags,
    description: overviews.imageGeneration.shortDescription,
    heading: modulesNames.imageGeneration,
    iconName: "YellowFrenchKey",
    path: basePath(IMAGE_GENERATION),
    id: "image-generation",
  },

  {
    tags: overviews.translator.tags,
    description: overviews.translator.shortDescription,
    heading: modulesNames.translator,
    iconName: "BlueLightBulb",
    path: "/translator",
    id: "translator",
  },
  {
    tags: overviews.competitorAnalysis.tags,
    description: overviews.competitorAnalysis.shortDescription,
    heading: modulesNames.competitorAnalysis,
    iconName: "BlueLightBulb",
    path: "/competitor-analysis",
    id: "competitor-analysis",
  },
  {
    tags: overviews.aiRiskAssessment.tags,
    description: overviews.aiRiskAssessment.shortDescription,
    heading: modulesNames.aiRiskAssessment,
    iconName: "BlueLightBulb",
    path: "/ai-risk-assessment",
    id: "ai-risk-assessment",
  },
  {
    tags: overviews.customerSupport.tags,
    description: overviews.customerSupport.shortDescription,
    heading: modulesNames.customerSupport,
    iconName: "BlueLightBulb",
    path: "/customer-support",
    id: "customer-support",
  },
  {
    tags: overviews.customerFeedback.tags,
    description: overviews.customerFeedback.shortDescription,
    heading: modulesNames.customerFeedback,
    iconName: "BlueLightBulb",
    path: "/customer-feedback",
    id: "customer-feedback",
  },
  {
    tags: overviews.onboarding.tags,
    description: overviews.onboarding.shortDescription,
    heading: modulesNames.onboarding,
    iconName: "BlueLightBulb",
    path: "/onboarding",
    id: "onboarding",
  },
  {
    tags: overviews.corporateSearch.tags,
    description: overviews.corporateSearch.shortDescription,
    heading: modulesNames.corporateSearch,
    iconName: "BlueLightBulb",
    path: "/corporate-search",
    id: "corporate-search",
  },
];
