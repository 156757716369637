import { Stack } from "@mui/material";
import linkedinService from "../../../../api/LinkedinService";
import { PrimaryButton } from "../../../../common/styles/Button.styles";
import { defaultGap } from "../../../../styles/consts";
import LinkedinDashboardBase, { Field } from "../LinkedinDashboardBase";

const fields: Field[] = [
  {
    name: "username",
    description: "LinkedIn username",
    default: "",
  },
  {
    name: "icp_keywords",
    description:
      "Keywords to search for (the ones you would put in the search field on LinkedIn)",
    default: "Head of, Director of, Manager",
  },
  {
    name: "icp_regions",
    description: "LinkedIn region codes separated by commas",
    default: "101282230",
    type: "list",
  },
  {
    name: "icp_profile_languages",
    description: "LinkedIn language codes separated by commas",
    default: "de",
    type: "list",
  },
  {
    name: "icp_search_limit",
    description: "Limit of profiles to search for",
    default: "50",
    type: "int",
  },
  {
    name: "icp_new_connections_limit",
    description: "Limit of profiles to connect with",
    default: "1",
    type: "int",
  },
  {
    name: "icp_keyword_title",
    description: "Part of job title to search for",
    default: "",
  },
  {
    name: "icp_invitation_message",
    description: "Message to send to new connections",
    default: "",
  },
  {
    name: "icp_enterprise_only",
    description: "Only connect with enterprise profiles",
    default: false,
    type: "bool",
  },
];

export default function ICPDashboard() {
  const createICPConnections = async (
    _e: React.FormEvent<HTMLFormElement>,
    body: Record<string, any>
  ) => {
    return await linkedinService.createICPConnections(body);
  };

  const runCareerChangeMonitoring = async () => {
    await linkedinService.runCareerChangeMonitoring({
      linkedin_cookies: localStorage.getItem("linkedin_cookies") || "",
      username: localStorage.getItem("username") || "maksymiliankalek",
    });
  };

  const monitoringButton = (
    <Stack
      sx={{
        padding: defaultGap,
      }}
    >
      <PrimaryButton onClick={runCareerChangeMonitoring}>
        Start monitoring
      </PrimaryButton>
    </Stack>
  );

  return (
    <LinkedinDashboardBase
      title="ICP Trigger 2 Sales Qualified Lead"
      fields={fields}
      onSubmit={createICPConnections}
      submitButtonText="Create ICP Connections"
      additionalButtons={monitoringButton}
      icp={true}
    />
  );
}
