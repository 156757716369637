import { perelynGrey } from "../../../styles/consts";
import { StyledRow, StyledRowDataBox } from "../../styles/Table.styles";
import Checkbox from "../fundamentals/Checkbox";

export type ItemRow = {
  sortParam?: string;
  label?: string;
  component?: JSX.Element;
  width: string;
  minWidth?: string;
  forceAlignLeft?: boolean;
};

export default function Row({
  itemRow,
  handleCheckboxClick,
  isChecked,
}: {
  itemRow: ItemRow[];
  handleCheckboxClick: () => void;
  isChecked: boolean;
}) {
  return (
    <StyledRow sx={{ backgroundColor: isChecked ? perelynGrey : "white" }}>
      {itemRow.map((item: ItemRow, idx: number) => (
        <StyledRowDataBox
          width={item.width}
          isLast={idx === itemRow.length - 1 && !item.forceAlignLeft}
          key={idx}
        >
          {idx === 0 && (
            <Checkbox
              iconName="WhiteCheck"
              onChange={handleCheckboxClick}
              isChecked={isChecked}
            />
          )}
          {item.component}
        </StyledRowDataBox>
      ))}
    </StyledRow>
  );
}
