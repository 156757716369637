import linkedinService from "../../../api/LinkedinService";
import LinkedinDashboardBase, { Field } from "./LinkedinDashboardBase";

const fields: Field[] = [
  {
    name: "keywords",
    default: "devops Azure Cloud Kubernetes",
  },
  {
    name: "schools",
    default:
      "15141415, 700268, 1311736, 18809, 623229, 166803, 12236, 564180, 9790, 1188458, 166283, 743470, 596185",
    type: "list",
  },
  {
    name: "regions",
    default: "101282230",
    type: "list",
  },
  {
    name: "profile_languages",
    default: "de",
    type: "list",
  },
  {
    name: "search_limit",
    default: "50",
    type: "int",
  },
  {
    name: "new_connections_limit",
    default: "1",
    type: "int",
  },
  {
    name: "keyword_title",
    default: "",
  },
  {
    name: "invitation_message",
    default: "",
  },
  {
    name: "min_total_experience_months",
    default: "24",
    type: "int",
  },
  {
    name: "min_relevant_experience_months",
    description:
      "Relevant experience in months (keyword_title is required for this)",
    default: "12",
    type: "int",
  },
];

export default function LinkedinDashboard() {
  const createConnections = async (
    _e: React.FormEvent<HTMLFormElement>,
    body: Record<string, any>
  ) => {
    return await linkedinService.createConnections(body);
  };

  return (
    <LinkedinDashboardBase
      fields={fields}
      onSubmit={createConnections}
      submitButtonText="Create connections"
    />
  );
}
