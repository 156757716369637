import { Stack, TextField } from "@mui/material";
import { useState } from "react";
import linkedinService from "../../../api/LinkedinService";
import { PrimaryButton } from "../../../common/styles/Button.styles";
import { MediumText, SmallText } from "../../../common/styles/Text.styles";
import { iconGap } from "../../../styles/consts";

export default function LinkedinAuthFields({ username, setUsername }) {
  const [linkedin_cookies, setLinkedin_cookies] = useState("");
  return (
    <>
      <Stack
        sx={{
          gap: iconGap,
          width: "50%",
        }}
      >
        <Stack>
          <MediumText>LinkedIn Username</MediumText>
        </Stack>
        <TextField
          value={username}
          onBlur={(e) => () => {
            localStorage.setItem("username", e.target.value);
          }}
          onChange={(e) => setUsername(e.target.value)}
        />
      </Stack>
      <Stack
        sx={{
          gap: iconGap,
          width: "50%",
        }}
      >
        <Stack>
          <MediumText>All LinkedIn Cookies</MediumText>
          <SmallText color="grey.500">
            All LinkedIn cookies from your browser from EditThisCookie. Once you
            save them here and have the username filled in it will take the
            latest working ones.
          </SmallText>
        </Stack>
        <TextField
          value={linkedin_cookies}
          onChange={(e) => setLinkedin_cookies(e.target.value)}
        />
        <PrimaryButton
          onClick={async () => {
            await linkedinService.updateLinkedinCredentials({
              username: username,
              cookies: linkedin_cookies,
            });
            setLinkedin_cookies("");
          }}
        >
          Save LinkedIn Cookies
        </PrimaryButton>
      </Stack>
    </>
  );
}
