import { PartialUserParams, User } from "../common/models/Models";
import { apiAxios } from "./axios";

const LOGOUT_URL = `/logout`;
const USER_URL = "/user";
const USERS_URL = "/users";
const REGISTER_URL = "/register";

class UserService {
  async adminRegisterUser(
    username: string,
    password: string,
    name: string,
    secret: string
  ) {
    try {
      return (
        await apiAxios.post<any>("/admin-register", {
          username,
          password,
          name,
          secret,
        })
      ).data;
    } catch (error) {
      throw new Error("Registration failed");
    }
  }

  async registerUser(username: string, name: string) {
    try {
      return (
        await apiAxios.post<any>(REGISTER_URL, {
          username,
          name,
        })
      ).data;
    } catch (error) {
      throw new Error("Registration failed");
    }
  }

  async verifyEmail(username: string, code: string) {
    return (
      await apiAxios.post<any>("/verify-email", {
        username,
        code,
      })
    ).data;
  }

  async resendVerificationCode(username: string) {
    return (
      await apiAxios.post<any>("/resend-verification-code", {
        username,
      })
    ).data;
  }

  async changePassword(
    currentPassword: string,
    newPassword: string,
    reEnteredPassword: string
  ) {
    return (
      await apiAxios.post<any>("/change-password", {
        currentPassword,
        newPassword,
        reEnteredPassword,
      })
    ).data;
  }

  async forgotPassword(email: string) {
    return (
      await apiAxios.post<any>("/forgot-password", {
        email,
      })
    ).data;
  }

  async resetPassword(code: string, newPassword: string, email: string) {
    return (
      await apiAxios.post<any>("/reset-password", {
        email,
        newPassword,
        code,
      })
    ).data;
  }

  async logout() {
    return (await apiAxios.post<any>(LOGOUT_URL)).data;
  }

  async getUser(): Promise<User> {
    return (await apiAxios.get<User>(USER_URL)).data;
  }

  async getUsers(): Promise<User[]> {
    return (await apiAxios.get<User[]>(USERS_URL)).data;
  }

  async getSalesUsers(): Promise<User[]> {
    return (await apiAxios.get<User[]>("/sales-users")).data;
  }

  async updateUser(
    userPropertiesToUpdate: PartialUserParams,
    setUser
  ): Promise<User> {
    const user = (await apiAxios.put<User>(USER_URL, userPropertiesToUpdate))
      .data;
    setUser(user);
    return user;
  }

  async activateUser(Id: string, active: boolean): Promise<User> {
    return (await apiAxios.put<User>("/activate-user", { Id, active })).data;
  }

  async deleteUserPrompt(name: string, setUser): Promise<User> {
    const user = (await apiAxios.delete<User>(`/user-prompt/${name}`)).data;
    setUser(user);
    return user;
  }

  async updateUserPrompt(
    userPropertiesToUpdate: PartialUserParams,
    setUser
  ): Promise<User> {
    const user = (
      await apiAxios.put<User>("/user-prompt", userPropertiesToUpdate)
    ).data;
    setUser(user);
    return user;
  }

  async deleteUserParameter(param: any, setUser): Promise<User> {
    const user = (
      await apiAxios.delete<User>(`/user-parameter/${param.name}`, {
        data: param,
      })
    ).data;
    setUser(user);
    return user;
  }

  async updateUserParameter(
    userPropertiesToUpdate: PartialUserParams,
    setUser
  ): Promise<User> {
    const user = (
      await apiAxios.put<User>("/user-parameter", userPropertiesToUpdate)
    ).data;
    setUser(user);
    return user;
  }

  async handleFederatedUser() {
    return (await apiAxios.post<any>("/handle-federated-user")).data;
  }
}

const userService = new UserService();

export default userService;
