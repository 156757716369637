import { createContext, useContext, useState } from "react";

import alqemiPostgresService from "../../../../api/PostgresService";
import { Offer } from "../../../../common/models/Models";

type OfferState = {
  offer: Offer;
  setOffer: (offer: Offer) => void;
  saveOffer: (
    newEmail: string,
    matchIndex: number,
    property: string
  ) => Promise<Offer>;
};

export const useOffer = (): OfferState => {
  const offerState = useContext(OfferContext);
  if (!offerState) {
    throw new Error("useOffer must be used within a OfferProvider");
  }
  return offerState;
};

const OfferContext = createContext<OfferState | null>(null);

type OfferProviderProps = {
  children: JSX.Element;
  preFetchedValues: [Offer];
};

const OfferProvider = ({ children, preFetchedValues }: OfferProviderProps) => {
  const [offer, setOffer] = useState<Offer>(preFetchedValues[0]);

  const saveOffer = async (
    value: string,
    matchIndex: number,
    property: string
  ) => {
    const match = offer.matches[matchIndex];

    await alqemiPostgresService.updateMatch(match.id, property, value);

    const updatedOffer = { ...offer };
    updatedOffer.matches[matchIndex][property] = value;
    setOffer(updatedOffer);
    return updatedOffer;
  };

  return (
    <OfferContext.Provider
      value={{
        offer,
        setOffer,
        saveOffer,
      }}
    >
      {children}
    </OfferContext.Provider>
  );
};

export default OfferProvider;
