import { Stack, Typography } from "@mui/material";
import Icon, {
  BiggerIcon,
} from "../../../../common/components/fundamentals/Icon";
import { OrangeOnHoverStack } from "../../../../common/styles/Box.styles";
import { PrimaryButton } from "../../../../common/styles/Button.styles";

import { useState } from "react";
import { MediumText } from "../../../../common/styles/Text.styles";
import { borderStyle, iconGap, smallGap } from "../../../../styles/consts";
import { formatDateFromPostgres } from "../../../../utils";

export default function CandidateCard({
  candidate,
  handleDelete,
  handleDateChange,
  handleEditDate,
  selectedDate,
}: {
  candidate: any;
  handleDelete: (id: string) => void;
  handleDateChange: (e: any) => void;
  handleEditDate: (id: string, newDate: any) => void;
  selectedDate: string;
}) {
  const [isEditing, setIsEditing] = useState(false);

  const handleSaveClick = async () => {
    await handleEditDate(candidate.id, selectedDate);
    setIsEditing(false);
  };

  return (
    <Stack
      sx={{
        border: borderStyle,
        width: "20rem",
        padding: iconGap,
        flexDirection: "row",
        gap: smallGap,
      }}
    >
      <Stack
        component={"img"}
        src="/pfpTest.jpg"
        sx={{ border: borderStyle, width: "4.5rem", height: "4.5rem" }}
      />
      <Stack
        sx={{
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <MediumText>{candidate.name}</MediumText>
          <OrangeOnHoverStack
            id={`delete-${candidate.name}`}
            sx={{
              "&&": { flexDirection: "column" },
              height: "fit-content",
            }}
            onClick={() => handleDelete(candidate.id)}
          >
            <BiggerIcon name="Trash" />
          </OrangeOnHoverStack>
        </Stack>
        <Typography>{candidate.email}</Typography>
        {isEditing ? (
          <Stack
            sx={{
              width: "100%",
              height: "fit-content",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "end",
            }}
          >
            <Stack
              component={"input"}
              type="date"
              onChange={handleDateChange}
              sx={{
                border: borderStyle,
                height: "1.25rem",
                justifyContent: "center",
              }}
            />

            <PrimaryButton sx={{ height: "1.25rem" }} onClick={handleSaveClick}>
              Save
            </PrimaryButton>
          </Stack>
        ) : (
          <Stack
            sx={{
              flexDirection: "row",
              gap: iconGap,
              alignItems: "center",
            }}
          >
            <Typography>Availability:</Typography>
            <OrangeOnHoverStack
              id="date"
              sx={{ flexDirection: "row", gap: iconGap, alignItems: "center" }}
              onClick={() => setIsEditing(true)}
            >
              <Typography>
                {formatDateFromPostgres(candidate.from_date)}
              </Typography>
              <Icon name="Edit" />
            </OrangeOnHoverStack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
