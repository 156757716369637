import { Stack, Typography } from "@mui/material";
import { OrangeOnHoverStack } from "../../../../common/styles/Box.styles";
import { HeadingText } from "../../../../common/styles/Text.styles";
import { borderStyle, smallerGap } from "../../../../styles/consts";

export default function OfferHeading({ title, companyName, url }) {
  return (
    <Stack
      sx={{
        borderBottom: borderStyle,
        width: "100%",
        padding: smallerGap,
        boxSizing: "border-box",
      }}
    >
      <Typography>{companyName}</Typography>
      <OrangeOnHoverStack onClick={() => window.open(url)}>
        <HeadingText id="title">{title}</HeadingText>
      </OrangeOnHoverStack>
    </Stack>
  );
}
