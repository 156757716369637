import { AlqemiConfig, AlqemiSimpleInput } from "../common/models/Models";
import { apiAxios } from "./axios";

const ALQEMI_RE_RUN_MATCHING_URL = "/re-run-matching";

class AlqemiService {
  async reRunMatching(
    cvPrompt: string,
    emailTemplate: string,
    emailPrompt: string,
    simpleInput: AlqemiSimpleInput
  ): Promise<AlqemiConfig> {
    const payload = {
      cvPrompt,
      emailTemplate,
      emailPrompt,
      inputData: simpleInput,
    };

    return (
      await apiAxios.post<AlqemiConfig>(ALQEMI_RE_RUN_MATCHING_URL, payload)
    ).data;
  }

  async sendAlqemiEmail(payload: any): Promise<void> {
    return (await apiAxios.post<void>("/send-alqemi-email", payload)).data;
  }
}

const alqemiService = new AlqemiService();
export default alqemiService;
